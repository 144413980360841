import React from 'react'
import './featured-component.scss'
import { H1 } from '../Heading'
import { Button } from '../Button'
import { Tag } from '../Tag'
import {
  displayDollar,
  displayPercentage,
  displayReturnsTitle,
  trimText,
} from '../../../lib/utils/helpers'
import { FeaturedProjectProps } from '../../types'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../src/store'
import { Anchor } from '../Anchor'
import { ASSET_CLASSES } from '../../utils/constants'

function Featured({ featuredProject, handleSaveForLater, handleClick }: FeaturedProjectProps) {
  const {
    projectSlug,
    projectName,
    projectSummary,
    irr,
    ticketSize,
    currencyCode,
    assetClass,
    isHighCommitenceProject,
    isSaved,
    id,
  } = featuredProject
  return (
    <div className='featured-container'>
      <div className='featured-top'>
        <div className='featured-top-left'>
          <p className='featured-title'>Featured Opportunity</p>
          <H1 classes={['mt-12']} text={projectName} />
          <div className='featured-stats-container'>
            {assetClass !== ASSET_CLASSES.LISTED_EQUITIES && Number(irr) !== 0 && (
              <p>
                {displayReturnsTitle(assetClass)}{' '}
                <strong>
                  {assetClass === ASSET_CLASSES.PRIVATE_EQUITY
                    ? displayDollar(irr, currencyCode)
                    : displayPercentage(irr) || ''}
                </strong>
              </p>
            )}
            <div className='vertical-separator'></div>
            <p>
              Ticket size <strong>{displayDollar(ticketSize, currencyCode)}</strong>
            </p>
          </div>
          <div className='featured-btn-container mt-25'>
            <Button
              variant='primary-btn'
              text='View details'
              handleClick={() => handleClick(projectSlug, id)}
            />
            {/* {!!authToken && (
              <Button
                variant='basic-btn'
                text='Save for later'
                iconVariant='right'
                IconComponent={
                  isSaved ? (
                    <FillBookmarkIcon type={'red'} classes={['ml-12']} />
                  ) : (
                    <BookmarkIcon type={'default'} classes={['ml-12']} />
                  )
                }
                handleClick={() => handleSaveForLater(id)}
              />
            )} */}
          </div>
        </div>
        <div className='featured-top-right'>
          <div className='tags-container'>
            {assetClass && <Tag classes={['margin-4']} text={assetClass} />}
            {isHighCommitenceProject && (
              <Tag
                classes={['margin-4']}
                text={isHighCommitenceProject && 'High Commitance Rate'}
              />
            )}
          </div>
          <p className='project-description'>
            {trimText(projectSummary, 180)} &nbsp;{' '}
            <Anchor
              text='know more'
              href={`/opportunity/${projectSlug}/${id}`}
              variant='basic-anchor'
            />
          </p>
        </div>
      </div>
    </div>
  )
}

export { Featured }
