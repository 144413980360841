/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import { P, Flex, Button, NotFoundProjectIcon } from '../../lib/components'
import { useNavigate } from 'react-router-dom'

const NoProjectSection = ({
  primaryText = 'Looks like you don’t have any opportunities in progress.',
  subText = 'Discover opportunities and invest in one to show up here.',
  buttonText = 'Discover opportunities',
  navigationPath = '/opportunities',
}: {
  primaryText?: string
  subText?: string
  buttonText?: string
  navigationPath?: string
}) => {
  const navigate = useNavigate()
  return (
    <Flex classes={['no-project-found-container']}>
      <NotFoundProjectIcon />
      <span> {primaryText} </span>
      <P textSize='small' variant='default-gray' text={subText} />
      <Button
        classes={['auto-btn-width', 'mt-25']}
        variant={'primary-btn'}
        text={buttonText}
        handleClick={() => {
          navigate(navigationPath)
        }}
      />
    </Flex>
  )
}

export { NoProjectSection }
