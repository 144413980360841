import React, { useMemo } from 'react'
import { Card, H5, SearchIcon } from '../../lib/components'
import { useNavigate } from 'react-router-dom'
import { displayDollar } from '../../lib/utils/helpers'
import { useBreakpoint } from '../../lib/utils/hooks'
import { HOLDINGS_TABLE_HEADERS, SM, TRANSACTIONS_TABLE_HEADERS } from '../../lib/utils/constants'
import { Pagination } from '../../lib/components/Pagination'
import { NoProjectSection } from './no-project-section'
import { DashboardHoldings, DashboardInvestmentTransactions } from '../../lib/types/propsType_v1'

interface HoldingsTransactionsCardProps {
  data: Array<DashboardHoldings | DashboardInvestmentTransactions> | undefined
  handleSearchParams?: ({ name, value }: { name: string; value: string }) => void
  isDashboardInvestmentView: boolean | undefined
  currentPage: number
  pagination: {
    total: number
    limit: number
    count: number
    offset: number
  }
  handleCurrentPage: (page: number) => void
}

const HoldingsTransactionsCard = ({
  data,
  handleSearchParams,
  isDashboardInvestmentView,
  currentPage,
  pagination,
  handleCurrentPage,
}: HoldingsTransactionsCardProps) => {
  const navigate = useNavigate()
  const { breakpoint } = useBreakpoint()
  const headers = isDashboardInvestmentView ? TRANSACTIONS_TABLE_HEADERS : HOLDINGS_TABLE_HEADERS
  const processedData = useMemo(() => {
    return (
      data?.filter(
        (item: DashboardHoldings | DashboardInvestmentTransactions) =>
          !('invested_amount' in item && item.invested_amount === null),
      ) ?? []
    )
  }, [data])

  const handleRowClick = (id: number) => {
    if (isDashboardInvestmentView) return

    navigate(`/dashboard/investments/${id}`)
  }

  const getTextColorClass = (key: string, item: any) => {
    if (!(key === 'total_gains' || key === 'transaction_type')) return ''

    if (key === 'total_gains') {
      const numericalVal = item?.[key]
      if (numericalVal === 0 || numericalVal === null) return ''

      return (numericalVal as number) > 0 ? 'text-color-profit' : 'text-color-loss'
    } else if (key === 'transaction_type') {
      const textValue = item?.[key] as string

      if (textValue.toLowerCase() === 'buy') {
        return 'text-color-profit'
      } else if (textValue.toLowerCase() === 'sell') {
        return 'text-color-loss'
      }
    }

    return ''
  }

  const getCellText = (key: string, value: string | number) => {
    if (key === 'transaction_date' || key === 'transaction_type') return value
    if (value === null) return 'N/A'

    const numericalVal = isNaN(Number(value)) ? 0 : Number(value)
    const sign = numericalVal > 0 ? '+' : ''
    const showSign = key === 'amount'

    let cellText = displayDollar(String(numericalVal), 'USD', 2, true, true)
    cellText = `${showSign ? sign : ''}${cellText.replace('$', '')}`

    return cellText
  }

  return (
    <Card classes={['transactions-card']}>
      <div className='transactions-card-heading'>
        <H5 text={isDashboardInvestmentView ? 'Transactions' : 'Holdings'} />
      </div>
      <div className='transactions-card-body'>
        {!isDashboardInvestmentView && (
          <div className='transactions-card-body-search-input'>
            <span className='transactions-card-body-search-input-icon'>
              <SearchIcon />
            </span>
            <input
              name='keyword'
              className='transactions-card-body-search-input-box'
              onChange={(e) =>
                handleSearchParams &&
                handleSearchParams({ name: e.target.name, value: e.target.value })
              }
              placeholder='Search by Opportunity Name'
            />
          </div>
        )}
        {!processedData || processedData?.length === 0 ? (
          <NoProjectSection primaryText="Looks like you don't have any holdings" />
        ) : (
          <div className='transactions-card-body-table-container'>
            <table className='transactions-card-body-table'>
              <thead>
                <tr>
                  <th className='transactions-card-body-table-cell-left-20'>Opportunity</th>
                  {Object.values(headers)?.map((item: string, index: number) => (
                    <th key={index}>{item}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {processedData?.map((item: any, index: number) => (
                  <tr
                    key={index}
                    onClick={() => handleRowClick(item.opportunity_id)}
                    className={
                      !isDashboardInvestmentView ? 'transactions-card-body-table-hover' : ''
                    }
                  >
                    <td className='transactions-card-body-table-cell-left-20'>
                      <div className='transactions-card-body-table-cell-opportunity-title'>
                        {item.opportunity_title}
                      </div>
                      <span className='transactions-card-body-table-cell-asset-class'>
                        {item.asset_class}
                      </span>
                    </td>
                    {Object.entries(headers)?.map(([key, _]: [string, string], index: number) => (
                      <td
                        key={index}
                        className={`transactions-card-body-table-cell ${getTextColorClass(
                          key,
                          item,
                        )}`}
                      >
                        {getCellText(key, item[key])}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {processedData?.length !== 0 && (
        <div className='transactions-card-footer'>
          {(breakpoint && breakpoint <= SM) || processedData?.length === 0 ? null : (
            <div className='projects-pagination-wrapper'>
              <div className='projects-pagination-wrapper-info'>
                Showing {pagination.offset + 1} - {pagination.offset + pagination.count} of total{' '}
                {pagination.total} results.
              </div>
              {pagination.count < pagination.total && (
                <Pagination
                  currentPage={currentPage}
                  handleCurrentPage={handleCurrentPage}
                  pagination={pagination}
                />
              )}
            </div>
          )}
        </div>
      )}
    </Card>
  )
}

export default HoldingsTransactionsCard
