import React from 'react'

import { Flex, H2, Input, Span, SelectDropdown, SearchIcon } from '../../lib/components'
import { OpportunityCard } from '../../lib/components/OpportunityCard'
import { Featured } from '../../lib/components/FeaturedProject'
import { Pagination } from '../../lib/components/Pagination'
import { ResetIcon } from '../../lib/components/Icon'
import { useBreakpoint } from '../../lib/utils/hooks'
import { SM } from '../../lib/utils/constants'
import { OpportunityCardProps, OpportunityListPresenterProps } from '../../lib/types'
import { calculateCommittedPercentage, trimText } from '../../lib/utils/helpers'
import { projectFilters, projectSort } from '../../lib/utils/message'
import './opportunity-list.scss'

function OpportunityListPresenter({
  projects,
  featuredProject,
  handleProjectClick,
  handleSaveForLater,
  searchParams,
  handleSearchParams,
  pagination,
  handleCurrentPage,
  currentPage,
}: OpportunityListPresenterProps) {
  const { breakpoint } = useBreakpoint()

  return (
    <>
      <Flex variant='column-center'>
        {featuredProject && featuredProject.projectName && (
          <Featured
            featuredProject={featuredProject}
            handleClick={handleProjectClick}
            handleSaveForLater={handleSaveForLater}
          />
        )}
        {featuredProject && featuredProject.coverImage && (
          <div
            className='opportunity-gray-background-image'
            style={{ background: `url(${featuredProject.coverImage}) no-repeat center center` }}
          ></div>
        )}

        <div className='text-left browse-heading-text'>
          <H2 text='Browse Opportunities' />
        </div>
        <div className='toolbar-layout gap-12 mb-32'>
          <div className='search-input'>
            <span className='search-input-icon'>
              <SearchIcon />
            </span>
            <Input
              name='keyword'
              value={searchParams.keyword}
              handleChange={(e) =>
                handleSearchParams({
                  name: e.target.name,
                  value: e.target.value,
                })
              }
              placeholder='Search Opportunities'
            />
          </div>
          <SelectDropdown
            name='filterBy'
            id='filterId'
            formId=''
            list={projectFilters}
            value={searchParams.filterBy}
            placeholder={'Select Category'}
            handleChange={(val) => handleSearchParams({ name: 'filterBy', value: val })}
            classes={['opportunity-search-select']}
          />
          <div className='sort-reset'>
            <SelectDropdown
              name='sortBy'
              id='sortById'
              formId=''
              list={projectSort}
              placeholder={'Sort'}
              value={searchParams.sortBy}
              handleChange={(val) => handleSearchParams({ name: 'sortBy', value: val })}
              classes={['opportunity-search-select']}
            />
            <div className='reset-btn'>
              <ResetIcon />
            </div>
          </div>
        </div>
        <div className='project-cards-container'>
          {projects.length > 0 ? (
            projects.map(
              ({
                id,
                opportunity_title,
                opportunity_slug,
                closing_date,
                opportunity_summary,
                asset_class,
                total_investment_amount,
                raise_goal,
                images,
                investment_return,
                ticket_size,
                cover_image,
                currency_code,
                short_opportunity_summary,
              }: OpportunityCardProps) => (
                <OpportunityCard
                  key={id}
                  id={id}
                  assetClass={asset_class}
                  irr={String(investment_return)}
                  ticketSize={String(ticket_size)}
                  projectSlug={opportunity_slug}
                  closingDate={closing_date}
                  projectName={opportunity_title}
                  projectSummary={short_opportunity_summary ?? trimText(opportunity_summary, 100)}
                  commitedPercentage={
                    total_investment_amount
                      ? calculateCommittedPercentage(
                          String(total_investment_amount) || '',
                          String(raise_goal) || '',
                        )
                      : '0%'
                  }
                  projectCover={cover_image ?? images[0]}
                  handleClick={handleProjectClick}
                  currencyCode={currency_code}
                />
              ),
            )
          ) : (
            <Span variant='thin' classes={['left']} text='Loading Opportunities..' />
          )}
        </div>
      </Flex>
      {(breakpoint && breakpoint <= SM) || projects.length === 0 ? null : (
        <>
          <div className='projects-pagination-wrapper'>
            <div className='projects-pagination-wrapper-info'>
              Showing {pagination.offset + 1} - {pagination.offset + pagination.count} of total{' '}
              {pagination.total} results.
            </div>
            <Pagination
              currentPage={currentPage}
              handleCurrentPage={handleCurrentPage}
              pagination={pagination}
            />
          </div>
        </>
      )}
    </>
  )
}

export { OpportunityListPresenter }
