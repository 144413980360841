import { axiosInstance } from '.'
import { OpportunityService, RawOpportunity, SearchParams } from '../lib/types'
import {
  UPLOAD_FILE,
  SEARCH_PROJECTS,
  SAVED_FOR_LATER,
  PREVIEW_FILE,
  GET_FEATURED_PROJECT,
  GET_LAST_MINUTE_PROJECTS,
  GET_ALL_OPPORTUNITY,
  SEARCH_OPPORTUNITY_V1,
  GET_OPPORTUNITY_BY_ID,
  GET_FEATURED_OPPORTUNITY,
} from './endpoints'
import { convertToURLSearchParams, removeEmptyValues } from '../lib/utils/helpers'

export const projectServices: OpportunityService = {
  postUploadFiles: async (filesData: File, headers: { [key: string]: string }) => {
    try {
      const formData = new FormData()
      formData.append('file', filesData)
      return await axiosInstance.post(UPLOAD_FILE, formData, {
        headers: {
          ...headers,
          'Content-Type': 'multipart/form-data',
        },
      })
    } catch (error: any) {
      return error.response
    }
  },
  getSearchProject: async (params: SearchParams, headers: { [key: string]: string }) => {
    try {
      const data = await axiosInstance.get(`${SEARCH_PROJECTS}`, {
        headers: headers,
        params: removeEmptyValues(params),
      })
      const searchedProjects = {
        projects: data.data.projects,
        pagination: {
          count: data.data.count,
          limit: data.data.limit,
          offset: data.data.offset,
          total: data.data.total,
        },
        status: data.status,
      }
      return searchedProjects
    } catch (error: any) {
      return error.response
    }
  },
  postSaveProject: async (projectId: string, headers: { [key: string]: string }) => {
    try {
      const data = await axiosInstance.post(
        `${SAVED_FOR_LATER}/${projectId}`,
        {},
        {
          headers: headers,
        },
      )
      return { projectId: data.data, status: data.status }
    } catch (error: any) {
      return error.response
    }
  },
  previewFile: async (fileKey: string, headers: { [key: string]: string }) => {
    try {
      const data = await axiosInstance.get(`${PREVIEW_FILE}/${fileKey}`, {
        headers: headers,
      })
      return data
    } catch (error: any) {
      return error.response
    }
  },
  getFeaturedProject: async (headers: { [key: string]: string }) => {
    try {
      const data = await axiosInstance.get(GET_FEATURED_PROJECT, {
        headers: headers,
      })
      return data
    } catch (error: any) {
      return error.response
    }
  },
  getLastMinuteProjects: async (headers: { [key: string]: string }) => {
    try {
      const data = await axiosInstance.get(GET_LAST_MINUTE_PROJECTS, {
        headers: headers,
      })
      return data
    } catch (error: any) {
      return error.response
    }
  },

  // v1
  getAllOpportunity: async (headers: { [key: string]: string }) => {
    try {
      const response = await axiosInstance.get(`${GET_ALL_OPPORTUNITY}`, {
        headers: headers,
      })
      return response
    } catch (error: any) {
      return error.response
    }
  },

  getSearchOpportunities: async (params: SearchParams, headers: { [key: string]: string }) => {
    try {
      const localParams = convertToURLSearchParams(removeEmptyValues(params))
      localParams.append('filterBy', 'PUBLISHED')

      const data = await axiosInstance.get(`${SEARCH_OPPORTUNITY_V1}`, {
        headers: headers,
        params: localParams,
      })
      const searchedOpportunities = {
        opportunities: data.data.opportunities,
        pagination: {
          count: data.data.count,
          limit: data.data.limit,
          offset: data.data.offset,
          total: data.data.total,
        },
        status: data.status,
      }
      return searchedOpportunities
    } catch (error: any) {
      return error.response
    }
  },

  getOpportunityById: async (opportunityId: string, headers: { [key: string]: string }) => {
    try {
      const data = await axiosInstance.get(GET_OPPORTUNITY_BY_ID(opportunityId), {
        headers: headers,
      })
      const {
        id,
        closing_date,
        opportunity_title,
        opportunity_slug,
        investment_return,
        ticket_size,
        opportunity_summary,
        metadata,
        jurisdiction,
        raise_goal,
        images,
        asset_class,
        is_featured,
        status,
        currency_code,
        total_investment_amount,
        no_of_investors,
        key_factors,
        terms,
        highlights,
        documents,
        updates,
        video,
        cover_image,
      }: RawOpportunity = data.data

      const opportunity = {
        id,
        closingDate: closing_date,
        projectName: opportunity_title,
        projectSlug: opportunity_slug,
        irr: investment_return,
        ticketSize: ticket_size,
        projectSummary: opportunity_summary,
        companyWebsite: metadata.company?.website,
        location: jurisdiction,
        raiseGoal: raise_goal,
        companyDetails: metadata.company?.about,
        keyPerformance: metadata.company?.key_performance,
        projectCover: cover_image ?? images[0],
        assetClass: asset_class,
        isFeatured: is_featured,
        companyName: metadata.company?.name,
        isPublished: status === 'PUBLISHED',
        currencyCode: currency_code,
        productImages: images,
        committedAmount: total_investment_amount,
        noOfInvestors: no_of_investors,
        keyFactors: key_factors,
        terms,
        documents: documents,
        updates,
        highlights,
        video,
        coverImage: cover_image,
      }
      return { data: opportunity, status: data.status }
    } catch (error: any) {
      return error.response
    }
  },

  getFeaturedOpportunity: async (headers: { [key: string]: string }) => {
    try {
      const params = new URLSearchParams()
      params.append('filterBy', 'PUBLISHED')
      const data = await axiosInstance.get(GET_FEATURED_OPPORTUNITY, {
        headers: headers,
        params,
      })
      return data
    } catch (error: any) {
      return error.response
    }
  },
}
