import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import {
  H2,
  H4,
  Span,
  Flex,
  FlexCenter,
  Input,
  Button,
  InvestIconHIW,
  EarnIconHIW,
  RedeemIconHIW,
  Line,
} from '../../lib/components'
import { OpportunityCard } from '../../lib/components/OpportunityCard'
import { RootState } from '../../store'
import { calculateCommittedPercentage, trimText } from '../../lib/utils/helpers'
import { OpportunityCardProps } from '../../lib/types'
import { LearnMoreSection } from './learn-more-section'
import './home.scss'
import { HomepageImageCarousel } from '../../lib/components/ImageCarousel'
import { LearnMoreIcon } from '../../lib/components/Icon'

const howWorks = [
  {
    title: 'Invest',
    Icon: InvestIconHIW,
    subTitle:
      'Pick the opportunity you would like to invest in. Subscribe to the offer and submit your payment to Credibila. If all looks good, your application will be accepted  within 4 business hours and you will be notified by our team on your registered email id',
  },
  {
    title: 'Earn',
    Icon: EarnIconHIW,
    subTitle:
      'During your investment journey, you will accrue returns aligned with the specific terms of the Opportunity you have invested in. Your returns, will be transferred directly to your bank account and you can track them in your portfolio as well',
  },
  {
    title: 'Redeem',
    Icon: RedeemIconHIW,
    subTitle:
      'During your investment journey with Credibila, you will gain returns aligned with the specific terms of the Opportunity you have invested in',
  },
]

const credibilaTrustContent = [
  {
    title: 'The Foundation of Trust',
    subTitle:
      'Trust is vital in investing. Our secure, transparent platform empowers informed decisions. With integrity and care, we ensure every transaction is secure, fostering trust for investor peace of mind.',
  },
  {
    title: 'Security and Responsibility',
    subTitle:
      'We prioritize security with robust measures to protect investments. Transparency enhances trust through clear reporting, empowering investors to make confident, informed decisions in a safe environment.',
  },
  {
    title: 'Expert Support for Success',
    subTitle:
      'Our expert team offers personalized support for all investors, from beginners to pros. With insights and guidance, we build lasting relationships based on trust, ensuring client success and satisfaction.',
  },
  {
    title: 'A Commitment to Trust',
    subTitle:
      'Trust is our core value. Through strong security, transparency, and top-tier support, we provide a platform where investors confidently achieve financial goals. Join us and invest with confidence today!',
  },
]

interface HomePresenterProps {
  handleButtonClick: () => void
  expand: boolean
  handleProjectClick: (projectSlug: string, id: number) => void
  projects: any
}

const HomePresenter = React.forwardRef<HTMLDivElement, HomePresenterProps>(
  ({ handleProjectClick, projects }) => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const { authToken } = useSelector((state: RootState) => state.investor)
    const loggedIn = authToken && authToken

    return (
      <>
        <div className='homepage-cover-wrapper'>
          <Flex variant='row' classes={['flex-4']}>
            <Flex variant='column-center'>
              <div className='homepage-header'>
                <H2 classes={['mb-24']} text='Welcome to Credibila' />
                <H4 text='Expertly-Crafted Investments, Accessible to All' classes={['mb-24']}></H4>
                <div>
                  <Link to={'/opportunities'} className='homepage-link'>
                    Learn more
                  </Link>
                  <div className='homepage-learn-more-icon'>
                    <LearnMoreIcon />
                  </div>
                </div>
              </div>
            </Flex>
          </Flex>

          <HomepageImageCarousel
            images={[
              'https://gbc-credibila-assets.s3.amazonaws.com/images/home-img1.png',
              'https://gbc-credibila-assets.s3.amazonaws.com/images/home-img2.png',
              'https://gbc-credibila-assets.s3.amazonaws.com/images/home-img3.png',
              'https://gbc-credibila-assets.s3.amazonaws.com/images/home-img4.png',
            ]}
          />

          <div className='homepage-cover-background'></div>
        </div>

        {/* Latest opportunity */}
        <FlexCenter classes={['just-launched-wrapper']}>
          <Flex variant='row' classes={['just-launched-card-container']}>
            <div className='project-cards-container'>
              {projects.length > 0 ? (
                projects.map(
                  ({
                    id,
                    opportunity_title,
                    opportunity_slug,
                    closing_date,
                    opportunity_summary,
                    total_investment_amount,
                    raise_goal,
                    images,
                    investment_return,
                    asset_class,
                    ticket_size,
                    currency_code,
                    cover_image,
                    short_opportunity_summary,
                  }: OpportunityCardProps) => (
                    <OpportunityCard
                      key={id}
                      id={id}
                      projectSlug={opportunity_slug}
                      closingDate={closing_date}
                      projectName={opportunity_title}
                      projectSummary={
                        short_opportunity_summary ?? trimText(opportunity_summary, 100)
                      }
                      commitedPercentage={
                        total_investment_amount
                          ? calculateCommittedPercentage(
                              String(total_investment_amount) || '',
                              String(raise_goal) || '',
                            )
                          : '0%'
                      }
                      irr={String(investment_return)}
                      projectCover={cover_image ?? images[0]}
                      assetClass={asset_class}
                      ticketSize={String(ticket_size)}
                      handleClick={handleProjectClick}
                      currencyCode={currency_code}
                    />
                  ),
                )
              ) : (
                <Span variant='thin' classes={['left']} text='Loading Opportunities..' />
              )}
            </div>
          </Flex>

          <div className='homepage-card-background'></div>
        </FlexCenter>

        {!loggedIn ? (
          <form onSubmit={() => navigate(`/signup/${email}`)}>
            <FlexCenter classes={[' mb-32']}>
              <Flex variant='column' classes={['homepage-signup-container']}>
                <span>Start investing today with as little as US $1000</span>
                <Flex classes={['homepage-signup-form']}>
                  <Input
                    name='email'
                    placeholder='Enter your email address...'
                    type='text'
                    handleChange={(e) => setEmail(e.target.value)}
                    required
                    value={email}
                  />
                  <Button
                    text='Sign Up'
                    classes={['homepage-signup-button']}
                    handleClick={() => navigate(`/signup/${email}`)}
                    variant='primary-btn'
                  />
                </Flex>
              </Flex>
            </FlexCenter>
          </form>
        ) : (
          <form onSubmit={() => navigate(`/signup/${email}`)}>
            <Flex classes={['homepage-start-investing-container', 'row-center']}>
              <span>Start investing today with as little as US $1000</span>
              <Button
                text='Explore'
                classes={['homepage-signup-button']}
                handleClick={() => navigate('/opportunities')}
                variant='primary-btn'
              />
            </Flex>
          </form>
        )}

        {/* How it Works */}
        <div className='how-it-works-wrapper'>
          <H2 text='How it Works?' classes={['how-it-works-wrapper-heading']} />
          <Line variant='border-1' classes={['how-it-works-line']} />
          <Flex variant='row' classes={['how-it-works-cards-container']}>
            {howWorks &&
              howWorks.map(({ title, subTitle, Icon }, idx) => (
                <div key={title} className='how-it-works-card'>
                  <Flex variant='column'>
                    <div className='how-it-works-card-icon'>
                      <Icon width='150' height='150' />
                    </div>
                    <Span
                      variant='thin'
                      text={`0${String(idx + 1)}`}
                      classes={['mb-12', 'how-it-works-counter']}
                    />
                    {idx === 2 && <div className='how-it-works-line-eraser'></div>}
                    <H2 classes={['how-it-works-card-heading']} text={title} />
                    <Span classes={['how-it-works-card-subheading']} text={subTitle} />
                  </Flex>
                </div>
              ))}
          </Flex>
        </div>

        {/* Why Credibila */}
        <div className='why-credibila-wrapper'>
          <Flex classes={['why-credibila-container']}>
            <img
              src='https://gbc-credibila-assets.s3.amazonaws.com/images/home-img5.png'
              alt='why credibila'
              className='why-credibila-img'
            />
            <Flex variant='column' classes={['why-credibila-content']}>
              <H2 text='Why Credibila?' />
              <p className='basic mt-24 regular'>
                Hey there! Credibila is super excited to have all you retail investors jump in and
                join us. You can start investing with just US $1000, how cool is that? It’s really
                easy to check out the potential returns and risks, and you can invest with just a
                click. We know flexibility is key, so lots of opportunities come with early
                redemption options. So what are you waiting for? Go grab those{' '}
                <Link to={'/opportunities'} className='homepage-link'>
                  Opportunities
                </Link>
                !
              </p>
            </Flex>
          </Flex>
        </div>

        {/* Trust with Confidence */}
        <div className='trust-container'>
          <H2 text='Trust with Confidence' />
          <Flex classes={['trust-content-wrapper', 'space-between', 'mt-36']}>
            <div className='trust-content'>
              {credibilaTrustContent.map((content, idx) => (
                <div className='mb-48' key={idx}>
                  <H4 text={content.title} classes={['heading trust-content-heading']} />
                  <span className='basic trust-content-descriptions'>{content.subTitle}</span>
                </div>
              ))}
            </div>
            <div className='trust-content'>
              <img
                src='https://gbc-credibila-assets.s3.amazonaws.com/images/home-img6.png'
                alt='trust with confidence'
                className='trust-content-img'
              />
            </div>
          </Flex>
        </div>

        {!loggedIn && (
          <form onSubmit={() => navigate(`/signup/${email}`)}>
            <FlexCenter classes={['homepage-start-investing-wrapper']}>
              <Flex classes={['homepage-start-investing-container']}>
                <span>Start investing today with as little as US $1000</span>
                <Button
                  text='Sign Up'
                  classes={['homepage-signup-button']}
                  handleClick={() => navigate(`/signup/${email}`)}
                  variant='primary-btn'
                />
              </Flex>
            </FlexCenter>
          </form>
        )}

        {/* Learn more */}
        <div className='learn-more-wrapper mt-56'>
          <Flex variant='row' classes={['benefit-container-wrapper']}>
            <div className='learn-more-img-container'>
              <img
                src='https://gbc-credibila-assets.s3.amazonaws.com/images/home-img7.png'
                alt='learn more'
              />
            </div>
            <div className='learn-more-content-container'>
              <H2 text='Learn more' />
              <LearnMoreSection />
            </div>
          </Flex>
        </div>
      </>
    )
  },
)

HomePresenter.displayName = 'HomePresenter'
export { HomePresenter }
