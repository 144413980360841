import { axiosInstance } from '../../services'
import { ADD_CRM_EVENT, UPDATE_CRM } from '../../services/endpoints'

export enum EngagementEvents {
  USER_LOGGED_IN_EMAIL = 'userLoggedInEmail',
  USER_LOGGED_IN_SOCIAL = 'userLoggedInSocial',
  USER_SIGNED_UP_EMAIL = 'userSignedUpEmail',
  USER_SIGNED_UP_SOCIAL = 'userSignedUpSocial',
  WELCOME_EMAIL_SENT = 'welcomeEmailSent',
  EMAIL_VERIFICATION_EMAIL_SENT = 'emailVerificationEmailSent',
  RESET_PASSWORD_CLICKED = 'resetPasswordClicked',
  RESET_PASSWORD_EMAIL_SENT = 'resetPasswordEmailSent',
  RESET_PASSWORD_DONE = 'resetPasswordDone',
  RESET_PASSWORD_CONFIRMATION_EMAIL_SENT = 'resetPasswordConfirmationEmailSent',
  USER_VERIFIED_EMAIL = 'userVerifiedEmail',
  VISITED_HOME_PAGE = 'visitedHomePage',
  CLICK_SIGNUP_BUTTON_HOMEPAGE = 'clickSignupButtonHomepage',
  VISITED_OPPORTUNITY = 'visitedOpportunity',
  ACCEPTED_CONFIDENTIALITY_AGREEMENT = 'acceptedConfidentialityAgreement',
  CLICKED_PROFILE_PAGE = 'clickedProfilePage',
  CLICKED_KYC_PAGE = 'clickedKycPage',
  STARTED_KYC = 'startedKyc',
  KYC_SENT_FOR_APPROVAL = 'kycSentForApproval',
  KYC_APPROVED = 'kycApproved',
  KYC_REJECTED = 'kycRejected',
  KYC_REJECTED_THREE_TIMES = 'kycRejectedThreeTimes',
  KYC_DOCUMENTS_HAVE_ISSUE = 'kycDocumentsHaveIssue',
  KYC_STATUS_UPDATE = 'kycStatusUpdate',
  INVESTOR_LIMIT_SET = 'investorLimitSet',
  INVESTMENT_LIMIT_UPDATED_EMAIL_SENT = 'investmentLimitUpdatedEmailSent',
  CLICKED_OPPORTUNITY_VIEW_DETAILS = 'clickedOpportunityViewDetails',
  VIEWED_OPPORTUNITY_DETAIL_PAGE = 'viewedOpportunityDetailPage',
  CLICKED_INVEST_BUTTON = 'clickedInvestButton',
  COMPLETED_FIRST_STEP_INVESTMENT = 'completedFirstStepInvestment',
  VIEWED_ACCEPTED_INVESTMENT_RISKS = 'viewedAcceptedInvestmentRisks',
  INVESTMENT_INITIATED_EMAIL_SENT = 'investmentInitiatedEmailSent',
  INVESTMENT_TRANSACTION_CHECKLIST_SENT = 'investmentTransactionChecklistSent',
  INVESTOR_VIEWED_TRANSACTION_CHECKLIST = 'investorViewedTransactionChecklist',
  INVESTOR_ACCEPTED_TRANSACTION_CHECKLIST = 'investorAcceptedTransactionChecklist',
  ACCEPT_TERMS_OF_BUSINESS = 'acceptTermsOfBusiness',
  INVESTOR_CONTACTED_SUPPORT = 'investorContactedSupport',
  CLIENT_AGREEMENT_SIGNED = 'clientAgreementSigned',
  PAYMENT_PROCESSED_SWIFT_COPY_UPLOADED = 'paymentProcessedSwiftCopyUploaded',
  INVESTMENT_ORDER_PLACED = 'investmentOrderPlaced',
  INVESTMENT_ORDER_INITIATED = 'investmentOrderInitiated',
  PAYMENT_RECEIVED_MANUAL_EMAIL_SENT = 'paymentReceivedManualEmailSent',
  INVESTMENT_APPROVED_MANUAL_EMAIL_SENT = 'investmentApprovedManualEmailSent',
  INVESTMENT_REJECTED_MANUAL_EMAIL_SENT = 'investmentRejectedManualEmailSent',
  UNITS_ASSIGNED_MANUAL_EMAIL_SENT = 'unitsAssignedManualEmailSent',
  ISSUE_WITH_SWIFT_COPY_MANUAL_EMAIL_SENT = 'issueWithSwiftCopyManualEmailSent',
  WITHDRAWAL_ORDER_PLACED = 'withdrawalOrderPlaced',
  WITHDRAWAL_REQUEST_CONFIRMED = 'withdrawalRequestConfirmed',
  WITHDRAWAL_REQUEST_EXECUTED = 'withdrawalRequestExecuted',
  ACCOUNT_DORMANCY_NOTIFICATION = 'accountDormancyNotification',
  ACCOUNT_DORMANCY_REMINDER_EMAILS = 'accountDormancyReminderEmails',
  CONSOLIDATED_PORTFOLIO_STATEMENT = 'consolidatedPortfolioStatement',
  TRANSACTION_HISTORY_EMAIL = 'transactionHistoryEmail',
}

export async function updateCrm(
  fieldName: string,
  fieldData: string,
  headers: { [key: string]: string },
): Promise<void> {
  try {
    await axiosInstance.post(
      UPDATE_CRM,
      {
        fieldName,
        fieldData,
      },
      {
        headers: headers,
      },
    )
  } catch (error: any) {
    return error.response
  }
}

export async function logCrmEvent(
  eventName: EngagementEvents,
  headers: { [key: string]: string },
  eventData?: object,
): Promise<void> {
  try {
    await axiosInstance.post(
      ADD_CRM_EVENT,
      {
        eventName,
        eventData: JSON.stringify(eventData),
      },
      {
        headers: headers,
      },
    )
  } catch (error: any) {
    return error.response
  }
}
