import React from 'react'
import { H3, Card, P, Flex } from '../../lib/components'
import { displayDollar, displayPercentage, displayReturnsTitle } from '../../lib/utils/helpers'
import { ASSET_CLASSES } from '../../lib/utils/constants'
import { OpportunityDetails } from '../../lib/types'

const InvestmentAmountProjectCard = ({ project }: { project: OpportunityDetails }) => {
  return (
    <div className='invest-amount-project-card'>
      <H3 text={project?.projectName} />

      <Flex classes={['flex-row-center']}>
        {project?.assetClass !== ASSET_CLASSES.LISTED_EQUITIES && Number(project.irr) !== 0 && (
          <Card classes={['default-property-card', 'mt-32', 'mr-32']}>
            <H3
              text={
                project?.assetClass === ASSET_CLASSES.PRIVATE_EQUITY
                  ? displayDollar(project?.irr, project?.currencyCode)
                  : displayPercentage(project?.irr) || ''
              }
            />
            <P
              classes={['mt-8']}
              textSize='small'
              variant='default-gray'
              text={displayReturnsTitle(project?.assetClass)}
            />
          </Card>
        )}
        <Card classes={['default-property-card', 'mt-32', 'mr-32']}>
          <H3 text={displayDollar(project?.ticketSize, project?.currencyCode) || ''} />
          <P classes={['mt-8']} textSize='small' variant='default-gray' text={'Ticket Size'} />
        </Card>
        <Card classes={['default-property-card', 'mt-32', 'mr-32']}>
          <H3 text={displayDollar(project?.raiseGoal, project?.currencyCode) || ''} />
          <P classes={['mt-8']} textSize='small' variant='default-gray' text={'Raise Goal'} />
        </Card>
        <Card classes={['default-property-card', 'mt-32', 'mr-32']}>
          <H3
            text={
              (project?.raiseGoal &&
                project?.committedAmount &&
                displayDollar(
                  (parseInt(project?.raiseGoal) - parseInt(project?.committedAmount)).toString(),
                  project?.currencyCode,
                )) ||
              ''
            }
          />
          <P
            classes={['mt-8']}
            textSize='small'
            variant='default-gray'
            text={'Remaining Raise Goal'}
          />
        </Card>
      </Flex>
    </div>
  )
}

export { InvestmentAmountProjectCard }
