import { axiosInstance } from '.'
import { SearchParams } from '../lib/types'
import { removeEmptyValues } from '../lib/utils/helpers'
import { DashboardService } from './apiTypes_v1'
import {
  GET_DASHBOARD_ACTIVE_INVESTMENTS,
  GET_DASHBOARD_ACTIVE_INVESTMENTS_BY_OPPORTUNITY_ID,
  GET_DASHBOARD_INVESTOR_HOLDINGS,
  GET_DASHBOARD_SUMMARY,
  GET_DASHBOARD_TRANSACTIONS_BY_OPPORTUNITY_ID,
  GET_INVESTMENT_SUMMARY,
} from './endpoints'
import { DashboardActiveInvestmentsParams } from './types'

export const dashboardServices: DashboardService = {
  getActiveInvestments: async (
    params: DashboardActiveInvestmentsParams,
    headers: { [key: string]: string },
  ) => {
    try {
      return axiosInstance.get(GET_DASHBOARD_ACTIVE_INVESTMENTS, {
        headers: headers,
        params: removeEmptyValues(params),
      })
    } catch (error: any) {
      return error.response
    }
  },

  // dashboard overview APIs
  getDashboardSummary: async (headers: { [key: string]: string }) => {
    try {
      return axiosInstance.get(GET_DASHBOARD_SUMMARY, {
        headers: headers,
      })
    } catch (error: any) {
      return error.response
    }
  },
  getInvestorHoldings: async (params: SearchParams, headers: { [key: string]: string }) => {
    try {
      const data = await axiosInstance.get(GET_DASHBOARD_INVESTOR_HOLDINGS, {
        params: removeEmptyValues(params),
        headers: headers,
      })
      return {
        ...data.data,
        holdings: data.data.holdings,
        limit: data.data.limit,
        offset: data.data.offset,
        total: data.data.total,
        count: Math.min(data.data.limit, data.data.holdings.length),
        status: data.status,
      }
    } catch (error: any) {
      return error.response
    }
  },

  // dashboard specific investment APIs
  getInvestmentSummary: async (opportunityId, headers: { [key: string]: string }) => {
    try {
      return axiosInstance.get(GET_INVESTMENT_SUMMARY(opportunityId), {
        headers: headers,
      })
    } catch (error: any) {
      return error.response
    }
  },

  getInvestmentsGraphsByOpportunityId: async (
    params: DashboardActiveInvestmentsParams,
    opportunityId: string,
    headers: { [key: string]: string },
  ) => {
    try {
      return axiosInstance.get(GET_DASHBOARD_ACTIVE_INVESTMENTS_BY_OPPORTUNITY_ID(opportunityId), {
        headers: headers,
        params: removeEmptyValues(params),
      })
    } catch (error: any) {
      return error.response
    }
  },
  getTransactionsByOpportunityId: async (
    opportunityId: string,
    params: SearchParams,
    headers: { [key: string]: string },
  ) => {
    try {
      const data = await axiosInstance.get(
        GET_DASHBOARD_TRANSACTIONS_BY_OPPORTUNITY_ID(opportunityId),
        {
          params: removeEmptyValues(params),
          headers: headers,
        },
      )

      return {
        ...data.data,
        transactions: data.data.txnHistory,
        pagination: {
          count: Math.min(data.data.limit, data.data.txnHistory.length),
          limit: data.data.limit,
          offset: data.data.offset,
          total: data.data.total,
        },
        count: Math.min(data.data.limit, data.data.txnHistory.length),
        status: data.status,
      }
    } catch (error: any) {
      return error.response
    }
  },
}
