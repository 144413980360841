import { kycStatusMap } from '../utils/message'
import { SearchParams } from './propsTypes'

export type AssetsClass = 'Trade Finance' | 'Fixed Income' | 'Private Equity' | 'Mutual Fund'
export type PublishingStatus = 'PUBLISHED' | 'UNPUBLISHED'
export type AccountStatus = 'ACTIVE' | 'INACTIVE'
export type AssistanceCategories =
  | 'Issue with Investment'
  | 'Issue with Investment Withdrawal'
  | 'Issue with Platform Functionality'
  | 'Issue with KYC Verification'
  | 'Issue with Refund'
  | 'General Query'
  | 'Issue with Account'

export type InvestmentDocumentStatus = 'CREATED' | 'OPENED' | 'COMPLETED'
export type InvestmentDocumentType = 'INVESTMENT_AGREEMENT'

export const InvestmentStatus_v1 = {
  PENDING: 'PENDING',
  INITIATED: 'INITIATED',
  APPROVED: 'APPROVED',
  CLOSED: 'CLOSED',
  REJECTED: 'REJECTED',
}

export type InvestmentStatusType_v1 = (typeof InvestmentStatus_v1)[keyof typeof InvestmentStatus_v1]

export type SubjectCategories = 'Onboard' | 'General' | 'Inquiry'

export interface InvestorContactUsFormProps_v1 {
  name: string
  email: string
  phone: string
  assistance_category: AssistanceCategories
  subject: string
  message: string
  attachments: File[]
}

export interface GuestContactUsFormProps_v1 {
  firstName: string
  lastName: string
  email: string
  subject: AssistanceCategories
  message: string
}

// Dashboard
export interface DashboardSummary {
  totalInvestedAmount: number
  totalReturnsTillDate: number
  realizedGains: number
  unrealizedGains: number
  investmentDistributions?: {
    asset_class: AssetsClass
    ratio: number
  }[]
}

export interface DashboardInvestmentDistribution {
  [key: string]: number
}

export interface DashboardActiveInvestment {
  currentInvestmentValue: number
  investments: DashboardInvestmentGraph[]
}
export interface DashboardInvestmentGraph {
  asset_class: string
  cover_image: string
  investment_return: string
  opportunity_id: number
  opportunity_title: string
  short_opportunity_summary: string
  values: DashboardInvestmentGraphValues[]
}

export interface DashboardInvestmentGraphValues {
  date: string
  currentInvestmentValue: number
}

export interface DashboardInvestmentsSingleGraph extends DashboardInvestmentGraphValues {
  opportunity_id: number
  opportunity_title: string
}
export interface DashboardInvestmentsGraphs {
  investments: Array<DashboardInvestmentsSingleGraph[]>
}

export interface DashboardHoldings {
  asset_class: string
  current_price: number
  invested_amount: number
  opportunity_id: number
  opportunity_title: string
  total_return: number
  total_gains: number
}

export interface DashboardInvestmentTransactionsRaw {
  date: string
  txn_type: string
  amount: number
}

export interface DashboardInvestmentTransactions {
  opportunity_title: string
  asset_class: string
  transaction_date: string
  transaction_type: string
  amount: number
}

export interface DashboardPresenterProps {
  name: string
  email: string
  kycStatus?: string
  dashboardSummary: DashboardSummary
  investmentDistribution?: DashboardInvestmentDistribution
  investmentsData: DashboardInvestmentsGraphs
  holdingsData?: DashboardHoldings[]
  transactionsData?: DashboardInvestmentTransactions[]
  inProgressInvestments: Array<Investment & RawOpportunity & UserDetails>
  userInvestmentDetail?: UserInvestmentDetails
  currentPage: number
  pagination: {
    total: number
    limit: number
    count: number
    offset: number
  }
  handleSearchParams?: (...args: any) => void
  handleCurrentPage: (page: number) => void
  isDashboardInvestmentView?: boolean
}

// user

export interface UserDetails {
  created_at: string
  updated_at: string
  email: string
  name: string
  is_verified: boolean
  account_status: AccountStatus
  investment_limit: string
  total_investment: string
  role: string
  crm_contact_id: string
  user_source: string
  investor_reference: string
  phone: string | null
  country: string | null
  address: string | null
}

// opportunity

export interface OpportunityTerm {
  level1:
    | {
        header: string
        termObjects: Array<{ title: string; content: string }>
      }
    | undefined
  level2:
    | {
        header: string
        termObjects: Array<{ title: string; content: string }>
      }
    | undefined
}

export interface RawOpportunity {
  id: number
  created_at: string
  updated_at: string
  opportunity_title: string
  opportunity_slug: string
  opportunity_summary: string
  is_featured: boolean
  asset_class: AssetsClass
  investment_return: string
  jurisdiction: string
  ticket_size: string
  closing_date: string
  currency_code: string
  raise_goal: string
  status: string
  video: string
  images: string[]
  documents: {
    url: string
    title: string
  }[]
  key_factors: string[]
  highlights: string[]
  terms: OpportunityTerm
  metadata: {
    company: {
      about: string
      website?: string
      key_performance?: string
      name?: string
    }
  }
  cover_image: string
  short_opportunity_summary: string
  coupon_payment_interval_in_months: number | null
  no_of_investors: number
  total_investment_amount: string
  updates: {
    date: string
    title: string
    description: string
  }[]
}

export type OpportunityCardProps = RawOpportunity

export interface OpportunityDetails {
  id: RawOpportunity['id']
  closingDate: RawOpportunity['closing_date']
  projectName: RawOpportunity['opportunity_title']
  projectSlug: RawOpportunity['opportunity_slug']
  irr: RawOpportunity['investment_return']
  ticketSize: RawOpportunity['ticket_size']
  projectSummary: RawOpportunity['opportunity_summary']
  companyWebsite: string
  location: RawOpportunity['jurisdiction']
  raiseGoal: RawOpportunity['raise_goal']
  companyDetails: string
  keyPerformance: string
  projectCover: RawOpportunity['cover_image'] | string
  assetClass: RawOpportunity['asset_class']
  isFeatured: RawOpportunity['is_featured']
  companyName: string
  isPublished: boolean
  currencyCode?: RawOpportunity['currency_code']
  productImages: RawOpportunity['images']
  committedAmount: RawOpportunity['total_investment_amount']
  noOfInvestors: RawOpportunity['key_factors']
  terms: RawOpportunity['terms']
  documents: RawOpportunity['documents']
  updates: RawOpportunity['updates']
  highlights: RawOpportunity['highlights']
  video: RawOpportunity['video']
  coverImage: RawOpportunity['cover_image']
  keyFactors: RawOpportunity['key_factors']

  isHideCompanyDetails?: boolean
  isHighCommitenceProject?: boolean
}

export interface FeaturedProjectProps_v1 {
  featuredProject: OpportunityCardProps
  handleClick: (projectSlug: string, id: number) => void
  handleSaveForLater: (id: number) => void
}

export interface OpportunityListPresenterProps {
  projects: RawOpportunity[]
  featuredProject: any
  currentPage: number
  searchParams: Required<SearchParams>
  handleSearchParams: ({ name, value }: { name: string; value: string }) => void
  handleProjectClick: (projectSlug: string, id: number) => void
  handleSaveForLater: (id: number) => void
  handleCurrentPage: (currentPage: number) => void
  pagination: {
    total: number
    limit: number
    count: number
    offset: number
  }
}

export interface OpportunityDetailPresenterProps {
  project: OpportunityDetails
  similarProjects: OpportunityDetails[]
  handleSimilarProjectClick: (projectSlug: string, id: number) => void
  investHandleClick: (projectSlug: string, id: number) => void
  saveForLaterHandleClick: () => void
  viewDocumentHandleClick: (legalDocuments: string) => void
  backToProjectHandler: () => void
  userInvestmentLatest?: Investment
  showContactusModal: boolean
  handleClickModal: () => void
  contactUsRef: React.RefObject<HTMLInputElement>
  investButtonProperty: {
    buttonText: string
    isDisable: boolean
    kycStatus: (typeof kycStatusMap)[number]
    investmentInfo: JSX.Element
  }
  kycStatus: (typeof kycStatusMap)[number]
}

export interface OpportunityDetailTopProps {
  project: OpportunityDetails
  investHandleClick: (projectSlug: string, id: number) => void
  saveForLaterHandleClick: () => void
  backToProjectHandler: () => void
  userInvestmentLatest?: Investment
  investButtonProperty?: {
    buttonText: string
    isDisable: boolean
    kycStatus: (typeof kycStatusMap)[number]
    investmentInfo: JSX.Element
  }
  kycStatus: (typeof kycStatusMap)[number]
}

export interface OpportunityCompanyDetailProps {
  project: OpportunityDetails
  investHandleClick: (projectSlug: string, id: number) => void
  viewDocumentHandleClick: (legalDocuments: string) => void
  userInvestmentLatest?: Investment
  investButtonProperty?: {
    buttonText: string
    isDisable: boolean
    kycStatus: (typeof kycStatusMap)[number]
    investmentInfo: JSX.Element
  }
}

// invest

export interface UserInvestmentDetails {
  canInvest: string
  investmentLimit: string
  kycStatus: string
  totalInvestment: string
}

export interface Investment {
  id: number
  created_at: string
  updated_at: string
  investor_id: number
  opportunity_id: number
  amount: string
  status: InvestmentStatusType_v1
  metadata: {
    completedSteps: number
    paymentCopy?: string
  }
  buy_date: string | null
  quantity: number | null
  unit_buy_price: number | null
  remaining_quantity: number | null
  platform_fee: {
    amount: number
    currency: string
  } | null
  order_id: string
  unit_allocation_date: string | null
}

export interface UpdateInvestmentPayload {
  investmentId: number
  userId: number
  projectId: number
  metadata: {
    completedSteps: number
    agreement?: string
  }
}

export interface InvestmentEssentialsFormModalProps {
  active: boolean
  handleOnClose(): void
  opportunity: OpportunityDetails
}

export interface InvestmentDocument {
  created_at: string
  updated_at: string
  investor_id: number
  status: InvestmentDocumentStatus
  document_type: InvestmentDocumentType
  document_url: string
  metadata: any
  document_id: string
}

export interface InvestPresenterProps {
  backToProjectHandler: () => void
  currentStep: number
  handleCurrentStep: (val: number) => void
  opportunity: OpportunityDetails
  userInvestmentDetail: UserInvestmentDetails
  investmentValue: number
  setInvestmentValue: React.Dispatch<React.SetStateAction<number>>
  investmentData: Investment
  handleDisableButton: () => boolean
  handleInvestmentAmount: () => void
  handleFileUpload: (fileData: File) => Promise<{ data: FileUpload; status: number } | undefined>

  handleCompletePayment: (swiftPaymentCopy: string, transactionNumber: string) => void
  navigateToPaymentSuccessScreen: () => void
  handleAgreement: () => void
  completedStep: number
  setIsApiError: React.Dispatch<React.SetStateAction<boolean>>
  agreementLink: string
  showContactUsModal: boolean
  handleShowContactUsModal: () => void
  setAgreement: React.Dispatch<React.SetStateAction<string>>
  getInvestmentDocument: (documentId: string) => void
  getSignedDocument: () => any
  getDocumentAccessToken: () => any
  getDocumentSigningLink: (accessToken: string) => any
  getDocumentDownloadLink: (accessToken: string, documentId: string) => any
  showInvestmentEssentialsModal: boolean
  setInvestmentEssentialsModal: React.Dispatch<React.SetStateAction<boolean>>
  isTransactionChecklistChecked: boolean
  setIsTransactionChecklistChecked: React.Dispatch<React.SetStateAction<boolean>>
  showTransactionChecklistModal: boolean
  setTransactionChecklistModal: React.Dispatch<React.SetStateAction<boolean>>
  acceptTOBCheckbox: boolean
  setAcceptTOBCheckbox: React.Dispatch<React.SetStateAction<boolean>>
  showPopupBlockedModal: boolean
}

export interface InvestmentAmountProps {
  project: OpportunityDetails
  userInvestmentDetail: UserInvestmentDetails
  investmentValue: number
  setInvestmentValue: React.Dispatch<React.SetStateAction<number>>
  handleShowContactUsModal: () => void
  handleDisableButton: () => boolean
  handleInvestmentAmount: () => void
  setIsApiError: React.Dispatch<React.SetStateAction<boolean>>
  investmentData: any
}

export interface LegalAgreementProps {
  setAgreement: React.Dispatch<React.SetStateAction<string>>
  getInvestmentDocument: (documentId: string) => any
  getSignedDocument: () => any
  getDocumentAccessToken: () => any
  getDocumentSigningLink: (accessToken: string) => any
  getDocumentDownloadLink: (accessToken: string, documentId: string) => Promise<string>
  agreementLink: string
  acceptTOBCheckbox: boolean
  setAcceptTOBCheckbox: React.Dispatch<React.SetStateAction<boolean>>
  opportunity: OpportunityDetails
}

// payment

export interface PaymentPresenterProps {
  handlePrintPaymentSummary: () => void
  opportunity: OpportunityDetails
  paymentPage: string
  handleViewSummary: () => void
  investmentData: Investment
  showContactUsModal: boolean
  handleShowContactUsModal: () => void
  handleFilePreview: (fileKey: string) => Promise<string>
}

export interface PaymentSummaryProps {
  handlePrintPaymentSummary: () => void
  opportunity: OpportunityDetails
  investmentData: any
  handleShowContactUsModal: () => void
  handleFilePreview: (fileKey: string) => Promise<string>
}

export interface PaymentUpload {
  opportunity: OpportunityDetails
  investment?: Investment
  handleFileUpload?: (fileData: File) => Promise<{ data: FileUpload; status: number } | undefined>
  handleCompletePayment?: (swiftPaymentCopy: string, transactionNumber: string) => void
}

// kyc

export interface KYCDetails {
  created_at: string
  updated_at: string
  investor_id: number
  is_nda_accepted: boolean
  status: string
  metadata: any
  investor_kyc_id: string
  investment_essentials_agreement: boolean
  accept_tob: boolean
  investor_portfolio_id: string
  transaction_checklist_agreement: boolean
}

export interface KYCPresenterProps {
  kycStatusData: string
  noOfKyc: number
  getKYC: () => Promise<void>
}

// file

export interface FileUpload {
  ETag: string
  ServerSideEncryption: string
  Location: string
  key: string
  Key: string
  Bucket: string
  url: string
}
