/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState, useMemo } from 'react'
import { P, H4, QuestionMarkIcon, Input, Anchor, Button } from '../../lib/components'
import { convertToNumber, convertNumberWithCommas } from '../../lib/utils/helpers'
import { MD } from '../../lib/utils/constants'
import { useBreakpoint } from '../../lib/utils/hooks'
import { InvestmentAmountProjectCard } from './investment-amount-project-card'
import { useNavigate } from 'react-router-dom'
import { InvestmentAmountProps } from '../../lib/types'

const InvestmentAmount = ({
  project,
  userInvestmentDetail,
  investmentValue,
  setInvestmentValue,
  handleShowContactUsModal,
  handleDisableButton,
  handleInvestmentAmount,
  setIsApiError,
  investmentData,
}: InvestmentAmountProps) => {
  const navigate = useNavigate()
  const [amount, setAmount] = useState<string>('0')
  const { breakpoint } = useBreakpoint()

  const canInvestAmount = useMemo(
    () =>
      investmentData?.investmentAmount
        ? parseInt(userInvestmentDetail?.canInvest) / 100 +
          parseInt(investmentData?.investmentAmount) / 100
        : parseInt(userInvestmentDetail?.canInvest) / 100,
    [userInvestmentDetail?.canInvest, investmentData?.investmentAmount],
  )

  useEffect(() => {
    setAmount(convertNumberWithCommas(investmentValue.toString()))
  }, [investmentValue])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleInvestErrorMessage = (val: number): { investmentAmount?: string } => {
    const remainingRaiseGoalAmount =
      (parseInt(project?.raiseGoal) - parseInt(project?.committedAmount)) / 100

    const ticketSize = parseInt(project?.ticketSize) / 100
    if (val > Math.min(remainingRaiseGoalAmount, canInvestAmount)) {
      return {
        investmentAmount: `Amount must be less and equal to $${convertNumberWithCommas(
          Math.min(remainingRaiseGoalAmount, canInvestAmount),
        )}`,
      }
    }
    if (val < ticketSize) {
      return {
        investmentAmount: `Amount must be greater and equal to $${convertNumberWithCommas(
          ticketSize,
        )}`,
      }
    }
    return {}
  }
  const questions = [
    {
      question: (
        <>
          The remaining raise goal of the opportunity is{' '}
          <strong>
            {' '}
            $
            {convertNumberWithCommas(
              parseInt(project?.raiseGoal) / 100 - parseInt(project?.committedAmount) / 100,
            )}
          </strong>
          .The investment amount should be less than the remaining raise goal of the opportunity.
          <br />{' '}
          <em>
            {' '}
            The remaining raise goal is calculated using the raise goal minus the approved
            investment amount for this opportunity by the Credibila Team.{' '}
          </em>{' '}
        </>
      ),
    },
    {
      question: (
        <>
          The ticket size for this opportunity is{' '}
          <strong> ${convertNumberWithCommas(parseInt(project?.ticketSize) / 100)} </strong>. The
          investment amount should be greater than or equal to the ticket size.
        </>
      ),
    },
    {
      question: (
        <>
          <strong> ${convertNumberWithCommas(canInvestAmount)}</strong> is your remaining investment
          limit. The investment amount should be less than or equal to{' '}
          <strong> ${convertNumberWithCommas(canInvestAmount)}</strong>. Please contact the{' '}
          <span onClick={() => handleShowContactUsModal()}>
            &nbsp;
            {<Anchor variant='basic-anchor' href='' text='Credibila Team' />}
            &nbsp;
          </span>{' '}
          to increase your investment limit in case invest more than{' '}
          <strong> ${convertNumberWithCommas(canInvestAmount)}</strong>.
        </>
      ),
    },
  ]
  return (
    <>
      {project && <InvestmentAmountProjectCard project={project} />}
      <div className='invest-amount-container'>
        <div className='invest-amount-column-container'>
          {/* <H2 text={'Enter the investment amount'} /> */}
          <div className='invest-amount-card'>
            <H4 text='Enter amount:' />
            <div>
              <span className='dollar-input-icon'> $ </span>
              <Input
                type='text'
                name='investmentAmount'
                placeholder='Amount'
                data-testid='investment amount-input'
                value={amount}
                handleChange={(e) => {
                  setInvestmentValue(convertToNumber(e.target.value))
                  setIsApiError(false)
                }}
                maxValue={canInvestAmount}
                errors={handleInvestErrorMessage(investmentValue)}
                touched={{ investmentAmount: true }}
                infoText={`The minimum investment amount for this opportunity is $${convertNumberWithCommas(
                  parseInt(project?.ticketSize) / 100,
                )} and the maximum investment amount for this opportunity is $${convertNumberWithCommas(
                  Math.min(
                    canInvestAmount,
                    (parseInt(project?.raiseGoal) - parseInt(project?.committedAmount)) / 100,
                  ),
                )}. `}
              />
            </div>
          </div>
          <div className='stepper-btn-container'>
            <Button
              classes={breakpoint && breakpoint > MD ? ['auto-btn-width'] : ['']}
              text={'Cancel'}
              variant='basic-btn'
              handleClick={() => {
                setIsApiError(false)
                navigate(`/opportunity/${project?.projectSlug}/${project?.id}`)
              }}
            />
            <Button
              classes={breakpoint && breakpoint > MD ? ['auto-btn-width'] : ['']}
              text={'Continue'}
              variant='primary-btn'
              isDisabled={handleDisableButton()}
              handleClick={() => {
                handleInvestmentAmount()
              }}
            />
          </div>
        </div>
        <div className='invest-amount-column-container'>
          {questions &&
            questions.map((data, index) => (
              <div key={index} className='invest-amount-container-card'>
                <div className='invest-amount-container-question-card'>
                  <QuestionMarkIcon />
                </div>
                <P text={data.question} textSize='regular' variant='default-gray' />
              </div>
            ))}
        </div>
      </div>
    </>
  )
}
export { InvestmentAmount }
