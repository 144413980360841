/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import { DashboardInfoSection } from './top-info-section'
import InvestmentDetails from './investment-details'
import OpportunityProgressCard from './opportunity-progress-card'
import HoldingsTransactionsCard from './holdings-transactions-card'
import SpecificInvestmentInfoSection from './specific-investment-info-section'
import { DashboardPresenterProps } from '../../lib/types/propsType_v1'

const DashboardPresenter = ({
  name,
  email,
  dashboardSummary,
  investmentDistribution,
  investmentsData,
  transactionsData,
  holdingsData,
  kycStatus,
  userInvestmentDetail,
  inProgressInvestments,
  isDashboardInvestmentView,
  currentPage,
  pagination,
  handleSearchParams,
  handleCurrentPage,
}: DashboardPresenterProps) => {
  return (
    <>
      {!isDashboardInvestmentView ? (
        <DashboardInfoSection
          name={name}
          email={email}
          kycStatus={kycStatus}
          userInvestmentDetail={userInvestmentDetail}
        />
      ) : (
        <SpecificInvestmentInfoSection />
      )}

      <InvestmentDetails
        isDashboardInvestmentView={isDashboardInvestmentView}
        dashboardSummary={dashboardSummary}
        investmentsData={investmentsData}
        distributionData={investmentDistribution}
      />

      <OpportunityProgressCard investments={inProgressInvestments} />

      <HoldingsTransactionsCard
        isDashboardInvestmentView={isDashboardInvestmentView}
        data={isDashboardInvestmentView ? transactionsData : holdingsData}
        handleSearchParams={handleSearchParams}
        currentPage={currentPage}
        pagination={pagination}
        handleCurrentPage={handleCurrentPage}
      />
    </>
  )
}

export { DashboardPresenter }
