import { axiosInstance } from '.'
import { InvestmentStatusType_v1 } from '../lib/types/propsType_v1'
import { InvestService } from './apiTypes_v1'
import { removeEmptyValues } from '../lib/utils/helpers'
import {
  GET_QUARTERLY_REPORT,
  GET_USER_INVESTMENT_V1,
  GET_INVESTMENT_LATEST_V1,
  GET_INVESTMENT_BY_OPPORTUNITY_ID,
  ADD_OPPORTUNITY_INVESTMENT,
  UPDATE_OPPORTUNITY_INVESTMENT,
  SEARCH_INVESTMENTS_V1,
  GET_INVESTMENT_METRICS_V1,
  GET_INVESTMENT_DOCUMENT,
  GET_SIGNED_DOCUMENT,
} from './endpoints'
import { InvestmentSearchParams } from './types'

export const investServices: InvestService = {
  getInvestmentQuarterlyReport: async (
    params: { investorId: number },
    headers: { [key: string]: string },
  ) => {
    try {
      const data = await axiosInstance.get(GET_QUARTERLY_REPORT, {
        headers: headers,
        params: params,
      })
      return data
    } catch (error: any) {
      return error.response
    }
  },

  // v1
  getUserInvestmentDetail_v1: async (headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.get(GET_USER_INVESTMENT_V1, {
        headers: headers,
      })
    } catch (error: any) {
      return error.response
    }
  },

  getInvestmentLatest_v1: async (
    params: { opportunityId: number },
    headers: { [key: string]: string },
  ) => {
    try {
      const data = await axiosInstance.get(GET_INVESTMENT_LATEST_V1, {
        params: params,
        headers: headers,
      })
      return data
    } catch (error: any) {
      return error.response
    }
  },

  getInvestmentByOpportunityId: async (
    opportunityId: string,
    status: InvestmentStatusType_v1,
    headers: { [key: string]: string },
  ) => {
    try {
      const response = await axiosInstance.get(
        `${GET_INVESTMENT_BY_OPPORTUNITY_ID}/${opportunityId}/${status}`,
        {
          headers: headers,
        },
      )
      return {
        data: response.data,
        status: response.status,
      }
    } catch (error: any) {
      return error.response
    }
  },

  postOpportunityInvestment: async (
    payload: {
      projectId: string
      investmentAmount: string
      metadata: { completedSteps: number }
    },
    headers: { [key: string]: string },
  ) => {
    try {
      return await axiosInstance.post(
        ADD_OPPORTUNITY_INVESTMENT,
        {
          opportunity_id: payload?.projectId && parseInt(payload.projectId),
          amount: payload?.investmentAmount && parseInt(payload.investmentAmount) * 100,
          metadata: payload?.metadata,
        },
        {
          headers: headers,
        },
      )
    } catch (error: any) {
      return error.response
    }
  },

  updateOpportunityInvestment: async (payload, headers) => {
    try {
      return await axiosInstance.patch(
        UPDATE_OPPORTUNITY_INVESTMENT,
        {
          id: payload.investmentId,
          investor_id: payload?.userId && Number(payload.userId),
          opportunity_id: payload?.projectId && Number(payload.projectId),
          amount: payload?.investmentAmount && Number(payload.investmentAmount) * 100,
          status: payload.status ?? 'PENDING',
          metadata: payload.metadata,
          platform_fee: payload?.platformFee && Number(payload?.platformFee),
        },
        {
          headers: headers,
        },
      )
    } catch (error: any) {
      return error.response
    }
  },

  searchInvestments_v1: async (
    params: InvestmentSearchParams,
    headers: { [key: string]: string },
    additionalQuery = '',
  ) => {
    try {
      const data = await axiosInstance.get(`${SEARCH_INVESTMENTS_V1}${additionalQuery}`, {
        params: removeEmptyValues(params),
        headers: headers,
      })
      return {
        ...data.data,
        investments: data.data.investments,
        pagination: {
          count: data.data.count,
          limit: data.data.limit,
          offset: data.data.offset,
          total: data.data.total,
        },
        status: data.status,
      }
    } catch (error: any) {
      return error.response
    }
  },

  getInvestmentMetrics_v1: async (headers: { [key: string]: string }) => {
    try {
      const data = await axiosInstance.get(GET_INVESTMENT_METRICS_V1, {
        headers: headers,
      })
      return data
    } catch (error: any) {
      return error.response
    }
  },

  getInvestmentDocument: async (documentId: string, headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.get(GET_INVESTMENT_DOCUMENT(documentId), {
        headers: headers,
      })
    } catch (error: any) {
      return error.response
    }
  },

  getInvestorSignedAgreement: async (headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.get(GET_SIGNED_DOCUMENT, {
        headers: headers,
      })
    } catch (error: any) {
      return error.response
    }
  },
}
