import React, { useEffect, useMemo, useState } from 'react'
import { DashboardPresenter } from './dashboard-presenter'
import { RootState } from '../../store'
import { useSelector, useDispatch } from 'react-redux'
import { dashboardServices, investServices } from '../../services'
import {
  DashboardInvestmentsGraphs,
  DashboardSummary,
  DashboardInvestmentTransactions,
  InvestmentStatus_v1,
  DashboardInvestmentGraphValues,
  DashboardInvestmentTransactionsRaw,
  Investment,
  RawOpportunity,
  UserDetails,
} from '../../lib/types/propsType_v1'
import { useNavigate, useParams } from 'react-router-dom'
import { InvestmentSearchParams } from '../../services/types'
import { calculateStartDateFromFilter, getFormattedDate } from '../../lib/utils/helpers'
import { showBanner } from '../../global-state/banner-slice'
import { dashboardGraphFilter } from '../../lib/utils/message'
import { SearchParams } from '../../lib/types'

const DashboardSpecificInvestmentContainer = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { opportunityId } = params
  const { authToken, email, name, userId } = useSelector((state: RootState) => state.investor)
  const [dashboardSummary, setDashboardSummary] = useState<DashboardSummary>({
    totalInvestedAmount: 0,
    totalReturnsTillDate: 0,
    realizedGains: 0,
    unrealizedGains: 0,
  })
  const [searchParams, setSearchParams] = useState<SearchParams>({
    limit: '10',
    offset: '0',
    sortBy: 'CREATED_AT_DESC',
  })
  const [opportunityDetails, setOpportunityDetails] = useState<{
    opportunity_asset_class: string
    opportunity_title: string
  }>({
    opportunity_asset_class: '',
    opportunity_title: '',
  })
  const [investmentsGraphData, setInvestmentsGraphData] = useState<DashboardInvestmentsGraphs>({
    investments: [[]],
  })
  const [transactionsData, setTransactionsData] = useState<DashboardInvestmentTransactions[]>([])
  const [investments, setInvestments] = useState<Array<Investment & RawOpportunity & UserDetails>>(
    [],
  )
  const [transactionsCurrentPage, setTransactionsCurrentPage] = useState(1)
  const [transactionsPagination, setTransactionsPagination] = useState({
    total: 10,
    limit: 5,
    count: 5,
    offset: 0,
  })
  const [investmentsGraphFilterValue, setInvestmentsGraphFilterValue] = useState<any>(
    dashboardGraphFilter[0],
  )

  useEffect(() => {
    const getUserInvestmentInOpportunity = async () => {
      return investServices.searchInvestments_v1(
        {
          investor_id: userId,
          limit: 1,
          offset: 0,
          opportunity_id: opportunityId,
        } as InvestmentSearchParams,
        {
          Authorization: authToken ? authToken : '',
        },
      )
    }
    // Fetch dashboard summary
    const getDashboardInvestmentSummary = async () => {
      const data = await dashboardServices.getInvestmentSummary(String(opportunityId), {
        Authorization: authToken ? authToken : '',
      })
      if (data && data.data && data.status === 200) {
        setDashboardSummary(data?.data)
      }
    }
    // Fetch dashboard investments values
    const getDashboardInvestmentsGraphs = async () => {
      const startDate = calculateStartDateFromFilter(investmentsGraphFilterValue.value)
      const data = await dashboardServices.getInvestmentsGraphsByOpportunityId(
        { startDate },
        String(opportunityId),
        {
          Authorization: authToken ? authToken : '',
        },
      )
      if (data && data.data && data.status === 200) {
        const response = data?.data
        setOpportunityDetails({
          opportunity_asset_class: response.asset_class,
          opportunity_title: response.opportunity_title,
        })
        const investments =
          response?.values?.map((item: DashboardInvestmentGraphValues) => ({
            date: item.date,
            currentInvestmentValue: item.currentInvestmentValue,
            opportunity_id: response.opportunity_id,
            opportunity_title: response.opportunity_title,
          })) ?? []
        setInvestmentsGraphData({ investments: [investments] })
      }
    }
    // Fetch specific investment values
    const getTransactionsData = async () => {
      const response = await dashboardServices.getTransactionsByOpportunityId(
        String(opportunityId),
        {
          ...searchParams,
        },
        {
          Authorization: authToken ? authToken : '',
        },
      )
      if (response.status === 200) {
        const transactions =
          response?.transactions?.map((item: DashboardInvestmentTransactionsRaw) => {
            const date = new Date(item.date)
            const dateString = getFormattedDate(date, 'DD-MM-YYYY')
            return {
              transaction_date: dateString,
              transaction_type: item.txn_type,
              amount: item.amount,
            } as DashboardInvestmentTransactions
          }) ?? []
        setTransactionsData(transactions)
        setTransactionsPagination({
          ...transactionsPagination,
          total: response?.total,
          limit: response?.limit ?? transactionsPagination.limit,
          count: response?.count ?? transactionsPagination.count,
          offset: response?.offset ?? transactionsPagination.offset,
        })
      }
    }

    // Fetch in-progress investment details
    const getInProgressInvestmentsList = async () => {
      const response = await investServices.searchInvestments_v1(
        {
          investor_id: userId,
          limit: 20,
          offset: 0,
          opportunity_id: opportunityId,
        } as InvestmentSearchParams,
        {
          Authorization: authToken ? authToken : '',
        },
        `?status=${InvestmentStatus_v1.PENDING}&status=${InvestmentStatus_v1.INITIATED}`,
      )
      if (response.status === 200) {
        setInvestments(response?.investments ?? [])
      }
    }

    if (authToken && userId) {
      getUserInvestmentInOpportunity().then((response) => {
        if (response.status === 200 && response?.count === 0 && response?.total === 0) {
          dispatch(
            showBanner({
              text: 'You do not have any investments in that opportunity',
              variant: 'warning-banner',
            }),
          )

          navigate('/dashboard')
        } else if (response.status === 200 && response?.count !== 0 && response?.total !== 0) {
          getDashboardInvestmentSummary()
          getDashboardInvestmentsGraphs()
          getTransactionsData()
          getInProgressInvestmentsList()
        }
      })
    }
  }, [])

  useEffect(() => {
    const getTransactionsData = async () => {
      const response = await dashboardServices.getTransactionsByOpportunityId(
        String(opportunityId),
        {
          ...searchParams,
        },
        {
          Authorization: authToken ? authToken : '',
        },
      )

      if (response?.status === 200) {
        const transactions =
          response?.transactions?.map((item: DashboardInvestmentTransactionsRaw) => {
            const date = new Date(item.date)
            const dateString = getFormattedDate(date, 'DD-MM-YYYY')
            return {
              transaction_date: dateString,
              transaction_type: item.txn_type,
              amount: item.amount,
            } as DashboardInvestmentTransactions
          }) ?? []
        setTransactionsData(transactions)
        setTransactionsPagination({
          ...transactionsPagination,
          total: response?.total,
          limit: response?.limit ?? transactionsPagination.limit,
          count: response?.count ?? transactionsPagination.count,
          offset: response?.offset ?? transactionsPagination.offset,
        })
      }
    }

    if (authToken) getTransactionsData()
  }, [transactionsCurrentPage])

  const handleCurrentPage = (currentPage: number) => {
    const { limit } = transactionsPagination
    setTransactionsCurrentPage(currentPage)

    setSearchParams({
      ...searchParams,
      offset: `${(currentPage - 1) * limit}`,
    })

    setTransactionsPagination({ ...transactionsPagination, offset: (currentPage - 1) * limit })
  }

  const processedTransactionsData = useMemo(() => {
    return (
      transactionsData?.map((item: DashboardInvestmentTransactions) => ({
        ...item,
        asset_class: opportunityDetails.opportunity_asset_class,
        opportunity_title: opportunityDetails.opportunity_title,
      })) ?? []
    )
  }, [transactionsData, opportunityDetails])

  return (
    <>
      <DashboardPresenter
        name={name}
        email={email}
        isDashboardInvestmentView
        dashboardSummary={dashboardSummary}
        investmentsData={investmentsGraphData}
        transactionsData={processedTransactionsData}
        inProgressInvestments={investments}
        currentPage={transactionsCurrentPage}
        pagination={transactionsPagination}
        handleCurrentPage={handleCurrentPage}
      />
    </>
  )
}

export { DashboardSpecificInvestmentContainer }
