/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable arrow-spacing */
import React, { useState, useRef, useEffect } from 'react'
import './navbar.scss'
import {
  Hamburger,
  CBLogoV2,
  Close,
  ExpandMoreIcon,
  DashboardIcon,
  ProfileIcon,
  LogoutIcon,
} from '../Icon'
import { Anchor } from '../Anchor'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../store'
import { Button } from '../Button'
import { useBreakpoint } from '../../utils/hooks'
import { LG, EVENTS } from '../../utils/constants'
import { useNavigate, Link } from 'react-router-dom'
import { userServices } from '../../../services'
import { resetConfiguration } from '../../../global-state/investor-slice'
import { showBanner } from '../../../global-state/banner-slice'
import { captureEvent } from '../../utils/posthogUtils/usePostHog'
import { EngagementEvents, logCrmEvent } from '../../utils/crmEvents'

const navLinks = [
  {
    href: '/opportunities',
    text: 'Opportunities',
  },
  {
    href: '/about',
    text: 'About Us',
  },
  {
    href: '/contact-us',
    text: 'Contact Us',
  },
  {
    href: '/faq',
    text: 'FAQ',
  },
]
const subLinks = [
  {
    value: '/dashboard',
    text: 'Dashboard',
    icon: <DashboardIcon />,
  },
  {
    value: '/profile/general',
    text: 'Profile',
    icon: <ProfileIcon />,
  },
  {
    value: '#',
    text: 'Logout',
    icon: <LogoutIcon />,
  },
]

const NavigationDropDown = ({
  displayName,
  logoutHandler,
  isEmailVerified,
}: {
  displayName: string
  logoutHandler: () => void
  isEmailVerified: boolean
}) => {
  const [active, setActive] = useState(false)
  const navigationDropdownRef = useRef<any>()
  const dispatch = useDispatch()

  useEffect(() => {
    document.addEventListener('click', (e: any) => {
      if (navigationDropdownRef.current && !navigationDropdownRef.current.contains(e.target))
        setActive(false)
    })
  }, [navigationDropdownRef])

  const navigate = useNavigate()
  return (
    <div className='select'>
      <div className='selectWrapper'>
        <div
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setActive(!active)
          }}
          className={`selectCustom js-selectCustom ${active ? 'isActive' : ''}`}
        >
          <div className='selectCustom-trigger'>
            {displayName}{' '}
            <ExpandMoreIcon classes={active ? ['expand-more-icon', 'ml-12'] : ['ml-12']} />{' '}
          </div>
          <div ref={navigationDropdownRef} className='selectCustom-options'>
            {subLinks.map(({ text, value, icon }) => (
              <div key={value}>
                {text !== 'Logout' && (
                  <div
                    onClick={() => {
                      if (isEmailVerified) navigate(value)
                      else
                        dispatch(
                          showBanner({
                            text: `Please verify your email address to view the ${text}.`,
                            variant: 'warning-banner',
                          }),
                        )
                    }}
                    className='selectCustom-option'
                    data-value={value}
                  >
                    {icon} {text}
                  </div>
                )}
                {text === 'Logout' && (
                  <div
                    onClick={() => {
                      captureEvent(EVENTS.user_logged_out, { section: text })
                      logoutHandler()
                    }}
                    className='selectCustom-option-logout'
                    data-value={value}
                  >
                    {icon} {text}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export const Navbar = ({
  setShowContactUsModal,
}: {
  setShowContactUsModal: (val: boolean) => void
}) => {
  const { authToken, name, email, isEmailVerified } = useSelector(
    (state: RootState) => state.investor,
  )
  const [isNavExpanded, setIsNavExpanded] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const loggedIn = authToken && authToken
  const { breakpoint } = useBreakpoint()

  const logoutHandler = async () => {
    const data: any = await userServices.userLogout_v1({
      Authorization: authToken ? authToken : '',
    })
    if (data.status === 200) {
      dispatch(resetConfiguration())
      window.location.reload()
    }
  }

  const firstName = name?.split(' ')?.[0]

  return (
    <div className='navigation-wrapper'>
      <nav className='navigation'>
        <Link
          to={'/home'}
          onClick={() => captureEvent(EVENTS.navigated_to_home, { button: 'Home' })}
        >
          <CBLogoV2 classes={['navigation-logo']} width={'168px'} />
        </Link>
        <button
          className='hamburger'
          onClick={() => {
            setIsNavExpanded(!isNavExpanded)
          }}
        >
          {!isNavExpanded && <Hamburger />}
          {isNavExpanded && <Close />}
        </button>
        <div
          className={
            isNavExpanded
              ? 'navigation-menu expanded'
              : `navigation-menu ${loggedIn ? 'loggedIn' : ''}`
          }
        >
          <ul className='nav-list'>
            <>
              {navLinks
                .filter((obj: any) => {
                  if (!loggedIn && obj.href !== '/saved-opportunities') return obj
                  if (loggedIn) return obj
                })
                .map(({ href, text }) => (
                  <li
                    className='nav-item'
                    key={href}
                    onClick={() => {
                      if (isNavExpanded) setIsNavExpanded(false)
                      if (!loggedIn && text === 'Contact Us') {
                        setShowContactUsModal(true)
                      }
                    }}
                  >
                    <Anchor
                      isDisabled={!loggedIn && text === 'Contact Us'}
                      href={href}
                      text={text}
                      variant='nav-link'
                    />
                  </li>
                ))}
            </>

            {!loggedIn && (
              <li
                className='nav-item'
                onClick={() => {
                  captureEvent(EVENTS.navigated_to_signup_page, {
                    button: 'Sign Up/ Log In',
                  })
                  if (isNavExpanded) setIsNavExpanded(false)
                }}
              >
                {isNavExpanded && (
                  <Anchor href={'/signup'} text={'Sign up/ Log In'} variant='nav-link-bold' />
                )}
              </li>
            )}

            {loggedIn && breakpoint && breakpoint <= LG && (
              <>
                <li
                  className='nav-item'
                  onClick={() => {
                    captureEvent(EVENTS.navigated_to_dashboard, {
                      button: 'Dashboard',
                    })
                    if (isNavExpanded) setIsNavExpanded(false)
                  }}
                >
                  <Anchor href='/dashboard' text='Dashboard' variant='nav-link' />
                </li>
                <li
                  className='nav-item'
                  onClick={() => {
                    captureEvent(EVENTS.navigated_to_profile, {
                      button: 'Profile',
                    })
                    if (isNavExpanded) setIsNavExpanded(false)
                  }}
                >
                  <Anchor href='/profile/general' text='Profile' variant='nav-link' />
                </li>
                <li className='nav-item'>
                  <div
                    onClick={() => {
                      logoutHandler()
                    }}
                  >
                    <Anchor href='' text='Logout' variant='nav-link-bold' />
                  </div>
                </li>
              </>
            )}
          </ul>
        </div>

        {!loggedIn && breakpoint && breakpoint >= LG && (
          <Button
            text='Sign Up/Log In'
            classes={['navbar-btn-padding']}
            variant='primary-btn'
            handleClick={() => {
              captureEvent(EVENTS.navigated_to_signup_page, {
                button: 'Sign Up/ Log In',
              })

              navigate('/signup')
            }}
          />
        )}

        {loggedIn && breakpoint && breakpoint >= LG && (
          <div className='nav-item'>
            <NavigationDropDown
              logoutHandler={logoutHandler}
              displayName={firstName?.length > 0 ? firstName : email}
              isEmailVerified={isEmailVerified}
            />
          </div>
        )}
      </nav>
    </div>
  )
}
