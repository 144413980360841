import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { investServices, kycServices, dashboardServices } from '../../services'
import { RootState } from '../../store'
import { DashboardPresenter } from './dashboard-presenter'
import { updateConfiguration } from '../../global-state/investor-slice'
import './dashboard.scss'
import {
  DashboardHoldings,
  DashboardInvestmentDistribution,
  DashboardInvestmentGraph,
  DashboardInvestmentGraphValues,
  DashboardInvestmentsGraphs,
  DashboardInvestmentsSingleGraph,
  DashboardSummary,
  Investment,
  InvestmentStatus_v1,
  RawOpportunity,
  UserDetails,
  UserInvestmentDetails,
} from '../../lib/types/propsType_v1'
import { useDebounce } from '../../lib/utils/hooks'
import { dashboardGraphFilter } from '../../lib/utils/message'
import { calculateStartDateFromFilter } from '../../lib/utils/helpers'
import { InvestmentSearchParams } from '../../services/types'
import { SearchParams } from '../../lib/types'

const DashboardContainerNew = () => {
  const dispatch = useDispatch()
  const { authToken, email, name, kycStatus, userId } = useSelector(
    (state: RootState) => state.investor,
  )
  const [userInvestmentDetail, setUserInvestmentDetail] = useState<UserInvestmentDetails>()
  const [investments, setInvestments] = useState<Array<Investment & RawOpportunity & UserDetails>>(
    [],
  )
  const [kycStatusData, setKycStatusData] = useState(kycStatus)
  const [searchParams, setSearchParams] = useState<InvestmentSearchParams>({
    limit: 20,
    offset: 0,
    sortBy: 'CREATED_AT_DESC',
  })
  const [holdingsSearchParams, setHoldingsSearchParams] = useState<SearchParams>({
    limit: '10',
    offset: '0',
    sortBy: 'CREATED_AT_DESC',
  })
  const [currentPage, setCurrentPage] = useState(1)
  const [holdingsCurrentPage, setHoldingsCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    total: 10,
    limit: 5,
    count: 5,
    offset: 0,
  })
  const [holdingsPagination, setHoldingsPagination] = useState({
    total: 10,
    limit: 1,
    count: 1,
    offset: 0,
  })
  const [investmentDistribution, setInvestmentDistribution] =
    useState<DashboardInvestmentDistribution>({})
  const [dashboardSummary, setDashboardSummary] = useState<DashboardSummary>({
    totalInvestedAmount: 0,
    totalReturnsTillDate: 0,
    realizedGains: 0,
    unrealizedGains: 0,
  })

  const [investmentsGraphData, setInvestmentsGraphData] = useState<DashboardInvestmentsGraphs>({
    investments: [[]],
  })
  const [holdingsData, setHoldingsData] = useState<DashboardHoldings[]>([])
  const [investmentsGraphFilterValue, setInvestmentsGraphFilterValue] = useState<any>(
    dashboardGraphFilter[0],
  )

  useEffect(() => {
    const getUserInvestments = async () => {
      const response = await investServices.getUserInvestmentDetail_v1({
        Authorization: authToken ? authToken : '',
      })
      if (response && response?.data && response?.status === 200) {
        setUserInvestmentDetail(response?.data)
      }
    }
    const getUserInvestmentsList = async () => {
      const response = await investServices.searchInvestments_v1(
        {
          ...searchParams,
          investor_id: userId,
          opportunity_id: searchParams.projectId,
        },
        {
          Authorization: authToken ? authToken : '',
        },
        `?status=${InvestmentStatus_v1.PENDING}&status=${InvestmentStatus_v1.INITIATED}`,
      )
      if (response?.status === 200) {
        setInvestments(response?.investments)
        setPagination({
          ...pagination,
          total: response?.total,
          limit: response?.limit,
          count: response?.count,
          offset: response?.offset,
        })
      }
    }

    const getKyc = async () => {
      const response = await kycServices.getKYC_v1(userId.toString(), {
        Authorization: authToken ? authToken : '',
      })

      if (!!response && response.data) {
        dispatch(
          updateConfiguration({
            name: 'kycStatus',
            value: response.data.status,
          }),
        )
        dispatch(
          updateConfiguration({
            name: 'acceptedNDA',
            value: response.data.is_nda_accepted,
          }),
        )
        setKycStatusData(response.data.status ? response.data.status : '')
      }
    }
    // Fetch dashboard summary
    const getDashboardSummary = async () => {
      const data = await dashboardServices.getDashboardSummary({
        Authorization: authToken ? authToken : '',
      })
      if (data && data.data && data.status === 200) {
        const { investmentDistributions, ...summary } = data.data
        const distributionData =
          investmentDistributions?.reduce(
            (acc: { [key in string]: number }, curr: { ratio: number; asset_class: string }) => {
              acc[curr.asset_class] = curr.ratio
              return acc
            },
            {},
          ) ?? {}

        setInvestmentDistribution(distributionData)
        setDashboardSummary(summary)
      }
    }

    // Fetch dashboard investments values
    const getDashboardInvestmentsGraphs = async () => {
      const startDate = calculateStartDateFromFilter(investmentsGraphFilterValue.value)
      const data = await dashboardServices.getActiveInvestments(
        { startDate },
        {
          Authorization: authToken ? authToken : '',
        },
      )

      if (data && data?.data && data?.status === 200) {
        const response = data?.data
        const investments = response?.investments?.reduce(
          (acc: any, curr: DashboardInvestmentGraph) => {
            const currentInvestments: DashboardInvestmentsSingleGraph[] =
              curr?.values?.map((item: DashboardInvestmentGraphValues) => ({
                date: item.date,
                currentInvestmentValue: item.currentInvestmentValue,
                opportunity_id: curr?.opportunity_id,
                opportunity_title: curr?.opportunity_title,
              })) ?? []
            return [...acc, currentInvestments]
          },
          [],
        )

        setInvestmentsGraphData({ investments: investments })
      }
    }

    const getHoldingsData = async () => {
      const response = await dashboardServices.getInvestorHoldings(
        {
          ...holdingsSearchParams,
        },
        {
          Authorization: authToken ? authToken : '',
        },
      )

      if (response?.status === 200) {
        setHoldingsData(response?.holdings ?? [])

        setHoldingsPagination({
          ...holdingsPagination,
          total: response?.total,
          limit: response?.limit,
          count: response?.count,
          offset: response?.offset,
        })
      }
    }

    if (authToken && userId) {
      getUserInvestmentsList()
      getUserInvestments()

      getKyc()
      getDashboardSummary()
      getDashboardInvestmentsGraphs()
      getHoldingsData()
    }
  }, [])

  useEffect(() => {
    const getUserInvestmentsList = async () => {
      const response = await investServices.searchInvestments_v1(
        {
          ...searchParams,
          investor_id: userId,
          opportunity_id: searchParams.projectId,
        },
        {
          Authorization: authToken ? authToken : '',
        },
        `?status=${InvestmentStatus_v1.PENDING}&status=${InvestmentStatus_v1.INITIATED}`,
      )
      if (response?.status === 200) {
        setInvestments(response?.investments)
        setPagination({
          ...pagination,
          total: response?.total,
          limit: response?.limit,
          count: response?.count,
          offset: response?.offset,
        })
      }
    }

    if (authToken) getUserInvestmentsList()
  }, [currentPage, searchParams])

  useEffect(() => {
    const getHoldingsData = async () => {
      const response = await dashboardServices.getInvestorHoldings(
        {
          ...holdingsSearchParams,
        },
        {
          Authorization: authToken ? authToken : '',
        },
      )
      if (response?.status === 200) {
        setHoldingsData(response?.holdings ?? [])
        setHoldingsPagination({
          ...holdingsPagination,
          total: response?.total,
          limit: response?.limit,
          count: response?.count,
          offset: response?.offset,
        })
      }
    }

    if (authToken) getHoldingsData()
  }, [holdingsCurrentPage, holdingsSearchParams])

  const debouncedHandleSearchParams = useDebounce(
    ({ name, value }: { name: string; value: string }) => {
      const { limit } = holdingsPagination

      setHoldingsSearchParams({
        ...holdingsSearchParams,
        offset: '0',
        limit: limit.toString(),
        [name]: value,
      })
      setHoldingsCurrentPage(1)
      setHoldingsPagination({
        ...holdingsPagination,
        offset: 0,
      })
    },
    500,
  )

  const handleSearchParams = async ({ name, value }: { name: string; value: string }) => {
    debouncedHandleSearchParams({ name, value })
  }

  const handleCurrentPage = (currentPage: number) => {
    const { limit } = holdingsPagination
    setHoldingsCurrentPage(currentPage)

    setHoldingsSearchParams({
      ...holdingsSearchParams,
      offset: `${(currentPage - 1) * limit}`,
    })

    setHoldingsPagination({ ...holdingsPagination, offset: (currentPage - 1) * limit })
  }

  return (
    <>
      <DashboardPresenter
        name={name}
        email={email}
        dashboardSummary={dashboardSummary}
        investmentDistribution={investmentDistribution}
        investmentsData={investmentsGraphData}
        holdingsData={holdingsData}
        kycStatus={kycStatusData}
        userInvestmentDetail={userInvestmentDetail}
        inProgressInvestments={investments}
        handleSearchParams={handleSearchParams}
        currentPage={holdingsCurrentPage}
        pagination={holdingsPagination}
        handleCurrentPage={handleCurrentPage}
      />
    </>
  )
}

export { DashboardContainerNew }
