import React from 'react'
import './updates.scss'
import { H4, H6, Flex, P, H5 } from '../../lib/components'
import { UPDATES } from '../../lib/utils/message'
import { formatDate } from '../../lib/utils/helpers'

interface UpdatesProps {
  updates: object[]
}

interface UpdateCardProps {
  date: Date
  title: string
  description: string
}

const UpdatesCard = ({ date, title, description }: UpdateCardProps) => (
  <Flex variant='row' classes={['mt-25', 'mb-16']}>
    <Flex variant='row' classes={['mr-40']}>
      <H6 text={formatDate(new Date(date))} />
    </Flex>
    <Flex variant='column' classes={['updates-card-detail-container']}>
      <H5 text={title} classes={['updates-card-detail-title', 'mb-29']} />
      <P text={description} textSize={'regular'} />
    </Flex>
  </Flex>
)

const Updates = ({ updates }: UpdatesProps) => {
  return (
    <Flex variant='column' classes={['mt-72']}>
      <H4 text={UPDATES} id='updates' />
      <Flex variant='column' classes={['mt-29', 'ml-16', 'updates-container']}>
        {updates.map(({ date, title, description }: any, idx) => (
          <Flex
            key={idx}
            variant='row'
            classes={['updates-card-container', updates.length - 1 !== idx ? 'border-bottom' : '']}
          >
            <UpdatesCard date={date} title={title} description={description} />
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}

export { Updates }
