import React, { useEffect, useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { FlexCenter } from '../../lib/components'
import { ResetPasswordPresenter } from './reset-password-presenter'
import { forgotPasswordSchema } from '../../lib/utils/schema'
import { userServices } from '../../services'
import { showBanner } from '../../global-state/banner-slice'
import { captureEvent } from '../../lib/utils/posthogUtils/usePostHog'
import { EVENTS } from '../../lib/utils/constants'

const initialValues = {
  password: '',
  passwordConfirmation: '',
}

const ResetPasswordContainer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const reRef = useRef<ReCAPTCHA>(null)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const checkResetPasswordLinkValid = async () => {
      const code = searchParams.get('code')
      const userId = searchParams.get('user')
      if (code && userId) {
        const response: any = await userServices.getIsResetPasswordLinkValid(userId, code)
        if (response.status !== 200) {
          dispatch(
            showBanner({
              text:
                (response.data && response.data.message) ||
                'Something went wrong! Please try again.',
              variant: 'error-banner',
            }),
          )
          navigate('/forgot-password')
        }
      }
    }
    checkResetPasswordLinkValid()
  }, [])

  const handleSubmit_v1 = async (val: any) => {
    const token = await reRef.current?.executeAsync()
    reRef.current?.reset()

    const code = searchParams.get('code')
    const userId = searchParams.get('user')
    if (code) {
      const res: any = await userServices.resetPassword_v1(
        {
          userId,
          code: code,
          password: val.password,
        },
        { 'g-recaptcha-response': token ? token : '' },
      )
      if (!!res && res.status === 200) {
        captureEvent(EVENTS.password_reset, { email: val.email })

        dispatch(
          showBanner({
            text: 'Password reset successfully',
            variant: 'success-banner',
          }),
        )
        navigate('/login')
      } else {
        dispatch(
          showBanner({
            text: (res.data && res.data.message) || 'Something went wrong! Please try again.',
            variant: 'error-banner',
          }),
        )
        navigate('/forgot-password')
      }
    }
  }

  return (
    <FlexCenter classes={['p-24', 'center-in-page-container']}>
      <ResetPasswordPresenter
        initialValues={initialValues}
        handleFormSubmit={handleSubmit_v1}
        validationSchema={forgotPasswordSchema}
        reRef={reRef}
      />
    </FlexCenter>
  )
}

export { ResetPasswordContainer }
