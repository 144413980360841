import { axiosInstance } from '.'
import {
  LOGGED_IN_USER,
  USER_CREATE_V1,
  SEND_VERIFICATION_LINK_V1,
  RESET_PASSWORD_V1,
  USER_SIGN_IN_V1,
  VERIFY_EMAIL_V1,
  USER_LOGOUT_V1,
  GET_PROFILE_V1,
  LOGGED_IN_USER_V1,
  UPDATE_PROFILE_V1,
  RESET_PASSWORD_TOKEN_VALID,
  GOOGLE_SIGN_IN_V1,
} from './endpoints'
import { NewUser, User, Verification } from '../lib/types'
import { removeEmptyValues } from '../lib/utils/helpers'

export const userServices = {
  getIsResetPasswordLinkValid: async (userId: string, code: string) => {
    try {
      return await axiosInstance.get(RESET_PASSWORD_TOKEN_VALID(code, userId))
    } catch (error: any) {
      console.log(error.response)
      return error.response
    }
  },
  getProfileForCurrentUser: async (headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.get(LOGGED_IN_USER, {
        headers: headers,
      })
    } catch (error) {
      return error
    }
  },

  // v1
  userSignUp_v1: async (userData: NewUser, headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.post(USER_CREATE_V1, userData, {
        headers: headers,
      })
    } catch (error: any) {
      return error.response
    }
  },

  verifyEmail_v1: async (code: string, user: string, type: Verification) => {
    try {
      return await axiosInstance.get(VERIFY_EMAIL_V1(code, user, type))
    } catch (error: any) {
      return error.response
    }
  },

  sendVerificationLink_v1: async (email: string, type: Verification) => {
    try {
      return await axiosInstance.post(SEND_VERIFICATION_LINK_V1, {
        email,
        type,
      })
    } catch (error: any) {
      return error.response
    }
  },

  resetPassword_v1: async (resetPasswordData: object, headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.post(RESET_PASSWORD_V1, resetPasswordData, {
        headers: headers,
      })
    } catch (error: any) {
      return error.response
    }
  },

  userLogin_v1: async (
    loginData: User & { role: 'INVESTOR' },
    headers: { [key: string]: string },
  ) => {
    try {
      return await axiosInstance.post(USER_SIGN_IN_V1, loginData, {
        headers: headers,
      })
    } catch (error: any) {
      return error.response
    }
  },

  userLogout_v1: async (headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.post(USER_LOGOUT_V1, {}, { headers: headers })
    } catch (error: any) {
      return error.response
    }
  },

  getProfileForCurrentUser_v1: async (headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.get(LOGGED_IN_USER_V1, {
        headers: headers,
      })
    } catch (error) {
      return error
    }
  },

  getUserProfile_v1: async (headers: { [key: string]: string }) => {
    try {
      const data = await axiosInstance.get(GET_PROFILE_V1, {
        headers: headers,
      })
      const profileData = {
        countryOfResidence: data.data.country ?? '',
        email: data.data.email ?? '',
        name: data.data.name ?? '',
        phoneNumber: data.data.phone ?? '',
      }
      return { data: profileData, status: data.status }
    } catch (error: any) {
      return error.response
    }
  },

  userProfileUpdate_v1: async (profileData: any, headers: { [key: string]: string }) => {
    try {
      if ('email' in profileData) delete profileData['email']
      const sanitizedData = removeEmptyValues(profileData)
      const payload = {
        name: sanitizedData.name,
        phone: sanitizedData.phoneNumber,
        country: sanitizedData.countryOfResidence,
        address: sanitizedData.address,
      }
      const data = await axiosInstance.patch(UPDATE_PROFILE_V1, payload, {
        headers: headers,
      })
      return data
    } catch (error: any) {
      return error.response
    }
  },

  googleSignIn_v1: async (loginData: { [key: string]: string }) => {
    try {
      return await axiosInstance.post(GOOGLE_SIGN_IN_V1, loginData)
    } catch (error: any) {
      return error.response
    }
  },
}
