import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useQuery } from '@tanstack/react-query'

import { FlexCenter } from '../../lib/components'
import { OpportunityListPresenter } from './opportunity-list-presenter'
import { OpportunityDetails, RawOpportunity, SearchParams } from '../../lib/types'
import { projectServices, kycServices } from '../../services'
import { RootState } from '../../store'
import { showBanner } from '../../global-state/banner-slice'
import { setPreviousUrl } from '../../lib/utils/helpers'
import { updateConfiguration } from '../../global-state/investor-slice'

function OpportunityListContainer() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { authToken, isEmailVerified, acceptedNDA, userId } = useSelector(
    (state: RootState) => state.investor,
  )
  const [featuredProject, setFeaturedProject] = useState<Partial<OpportunityDetails>>({})
  const [isSaveForLater, setIsSaveForLater] = useState(false)
  const [projects, setProjects] = useState<RawOpportunity[]>([])
  const [searchParams, setSearchParams] = useState<Required<SearchParams>>({
    limit: '8',
    offset: '0',
    sortBy: '',
    keyword: '',
    filterBy: '',
  })
  const [pagination, setPagination] = useState({
    total: 20,
    limit: 8,
    count: 5,
    offset: 0,
  })
  const [currentPage, setCurrentPage] = useState(1)

  // Caching
  const { data, error, isLoading } = useQuery({
    queryKey: ['opportunities'],
    queryFn: () => fetchAllOpportunities(),
  })

  const fetchAllOpportunities = async () => {
    return await projectServices.getSearchOpportunities(searchParams, {
      Authorization: authToken ? authToken : '',
    })
  }

  const handleSearchParams = async ({ name, value }: { name: string; value: string }) => {
    const { limit } = pagination
    setSearchParams({
      ...searchParams,
      offset: '0',
      limit: limit.toString(),
      [name]: value,
    })
    setCurrentPage(1)
    setPagination({ ...pagination, offset: 0 })
  }

  const resetSearchParams = async () => {
    setSearchParams({
      limit: '8',
      offset: '0',
      sortBy: '',
      keyword: '',
      filterBy: '',
    })
  }

  const handleCurrentPage = (currentPage: number) => {
    const { limit } = pagination
    setCurrentPage(currentPage)
    setSearchParams({
      ...searchParams,
      offset: `${(currentPage - 1) * limit}`,
    })
    setPagination({ ...pagination, offset: (currentPage - 1) * limit })
  }

  const handleProjectClick = (projectSlug: string, id: number) => {
    if (!!authToken && isEmailVerified) {
      if (!acceptedNDA) {
        setPreviousUrl(`/opportunity/${projectSlug}/${id}`)
      } else setPreviousUrl('')
      navigate(`/opportunity/${projectSlug}/${id}`)
    } else if (!!authToken && !isEmailVerified) {
      dispatch(
        showBanner({
          text: 'Please verify your email address to view the opportunity details.',
          variant: 'warning-banner',
        }),
      )
    } else {
      navigate('/login')
    }
  }

  const handleSaveForLater = async (id: number) => {
    const data: any = await projectServices.postSaveProject(id.toString(), {
      Authorization: authToken ? authToken : '',
    })
    if (data.status === 200) {
      setIsSaveForLater((preState) => !preState)
      setFeaturedProject((preState) => ({
        ...preState,
        isSaved: !isSaveForLater,
      }))
      if (!isSaveForLater) {
        dispatch(
          showBanner({
            text: 'Opportunity saved for later',
            variant: 'success-banner',
          }),
        )
      }
      if (isSaveForLater) {
        dispatch(
          showBanner({
            text: 'Opportunity removed from saved for later list',
            variant: 'success-banner',
          }),
        )
      }
    } else
      dispatch(
        showBanner({
          text: (data.data && data.data.message) || 'Something went wrong! Please try again.',
          variant: 'error-banner',
        }),
      )
  }

  useEffect(() => {
    if (data) {
      setProjects(data.opportunities || [])
      setPagination(data.pagination)
    }
  }, [data, error, isLoading])

  useEffect(() => {
    const fetchFeaturedProject = async () => {
      const data: any = await projectServices.getFeaturedOpportunity({
        Authorization: authToken ? authToken : '',
      })

      if (data.data && data.status === 200) {
        // TODO: Refactor this part with new featured opportunity design
        const featuredOpportunity = {
          projectSlug: data.data.opportunity_slug,
          projectName: data.data.opportunity_title,
          projectSummary: data.data.opportunity_summary,
          irr: data.data.investment_return,
          ticketSize: data.data.ticket_size,
          currencyCode: data.data.currency_code,
          assetClass: data.data.asset_class,
          coverImage: data.data.cover_image,
          id: data.data.id,
        }

        setFeaturedProject(featuredOpportunity)
        setIsSaveForLater(data.data.isSaved ? true : false)
      }
    }

    const getKYC = async () => {
      const response = await kycServices.getKYC_v1(userId.toString(), {
        Authorization: authToken ? authToken : '',
      })
      if (!!response && response.data && response.status === 200) {
        if (response.data.status) {
          dispatch(
            updateConfiguration({
              name: 'kycStatus',
              value: response.data.status,
            }),
          )
          dispatch(
            updateConfiguration({
              name: 'acceptedNDA',
              value: response.data.is_nda_accepted,
            }),
          )
        }
      }
    }
    authToken && getKYC()
    fetchFeaturedProject()
  }, [])

  return (
    <FlexCenter>
      <OpportunityListPresenter
        pagination={pagination}
        handleCurrentPage={handleCurrentPage}
        currentPage={currentPage}
        searchParams={searchParams}
        handleSearchParams={handleSearchParams}
        projects={projects}
        featuredProject={featuredProject}
        handleProjectClick={handleProjectClick}
        handleSaveForLater={handleSaveForLater}
      />
    </FlexCenter>
  )
}

export { OpportunityListContainer }
