/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect } from 'react'
import './opportunity-detail-container.scss'
import { Flex, CTABanner, Button, H2, Card } from '../../lib/components'
import { OpportunityDetailTop } from './OpportunityDetailPresenter-detail-top'
import { AboutCompany } from './about-company'
import { KeyPerformance } from './key-performance'
import { CompanyCard } from './company-card'
import { QUESTION_FOR_PROJECT, GET_IN_TOUCH_WITH_OWNER, CONTACT_US } from '../../lib/utils/message'
import { OpportunityDetailPresenterProps } from '../../lib/types'
import { ContactUsModal } from '../../lib/components/ContactUsModal'
import { ProjectCard } from '../../lib/components/ProjectCard'
import { OpportunitySectionSwitcher } from './opportunity-details-section-switcher'
import { Terms } from './terms'
import { Documents } from './documents'
import { Updates } from './updates'
import { Highlights } from './highlights'
import {
  calculateCommittedPercentage,
  displayPercentage,
  displayDollar,
} from '../../lib/utils/helpers'
import { MD } from '../../lib/utils/constants'
import { useBreakpoint } from '../../lib/utils/hooks'
import { HowInvestmentWorksImage } from '../../lib/components/Icon'

const OpportunityDetailPresenter = ({
  project,
  similarProjects,
  handleSimilarProjectClick,
  investHandleClick,
  saveForLaterHandleClick,
  viewDocumentHandleClick,
  backToProjectHandler,
  userInvestmentLatest,
  handleClickModal,
  showContactusModal,
  contactUsRef,
  investButtonProperty,
  kycStatus,
}: OpportunityDetailPresenterProps) => {
  const [isSectionSwitcherShrunk, setSectionSwitcherShrunk] = useState(false)
  const [sections, setSections] = useState<[string, string][]>([])
  const terms = (project as any).terms
  const { breakpoint } = useBreakpoint()

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY
      const shrinkTriggerHeight = 609
      if (scrollPosition > shrinkTriggerHeight) {
        setSectionSwitcherShrunk(true)
      } else {
        setSectionSwitcherShrunk(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    const finalSections: [string, string][] = []
    if (project?.highlights?.length > 0) {
      finalSections.push(['Highlights', 'highlights'])
    }

    if (project?.keyPerformance?.length > 0) {
      finalSections.push(['Key Performance Indicator', 'key-performance'])
    }

    if (project?.companyDetails?.length > 0) {
      finalSections.push(['About', 'about-company'])
    }

    if (terms.level1?.header?.length > 0 || terms.level2?.header?.length > 0) {
      finalSections.push(['Terms', 'terms'])
    }

    if (project?.documents?.length > 0) {
      finalSections.push(['Documents', 'documents'])
    }

    if (project?.updates?.length > 0) {
      finalSections.push(['Updates', 'updates'])
    }

    finalSections.push(['How Investment Works', 'how-investment-works'])

    setSections((oldSections) => [...oldSections, ...finalSections])
  }, [])

  return (
    <div className='project-detail-main-layout ml-32'>
      {project && (
        <>
          <Flex classes={['detail-layout']}>
            <OpportunityDetailTop
              project={project}
              investHandleClick={investHandleClick}
              saveForLaterHandleClick={saveForLaterHandleClick}
              backToProjectHandler={backToProjectHandler}
              userInvestmentLatest={userInvestmentLatest}
              investButtonProperty={investButtonProperty}
              kycStatus={kycStatus}
            />
          </Flex>
          {/* Section switcher */}
          {sections.length > 0 && (
            <Flex
              classes={[
                'mt-96',
                'detail-layout',
                breakpoint && breakpoint < MD ? 'position-sticky-top-20' : 'position-sticky-top-86',
                'z-10',
              ]}
            >
              <Card
                classes={['project-section-switcher-card', isSectionSwitcherShrunk ? 'shrunk' : '']}
              >
                <OpportunitySectionSwitcher opportunitySections={sections} />
              </Card>
            </Flex>
          )}

          <Flex classes={['mt-56', 'detail-layout']}>
            <Flex
              variant='column'
              classes={
                !project?.isHideCompanyDetails
                  ? ['about-company-details']
                  : ['about-company-details', 'width-auto']
              }
            >
              {project.highlights.length > 0 && <Highlights highlights={project.highlights} />}

              {project?.keyPerformance && (
                <KeyPerformance keyPerformance={project?.keyPerformance || ''} />
              )}

              <AboutCompany
                aboutCompany={project?.companyDetails || ''}
                productImages={project?.productImages}
                spaceAtTop={project.highlights.length > 0}
              />
              {(terms.level1?.header?.length > 0 || terms.level2?.header?.length > 0) && (
                <Terms level1={terms.level1} level2={terms.level2} />
              )}
              {project.documents?.length > 0 && <Documents documents={project.documents} />}
              {project.updates.length > 0 && <Updates updates={project.updates} />}
              <HowInvestmentWorksImage classes={['mt-24']} />
            </Flex>
            {!project?.isHideCompanyDetails && (
              <CompanyCard
                project={project}
                investHandleClick={investHandleClick}
                viewDocumentHandleClick={viewDocumentHandleClick}
                userInvestmentLatest={userInvestmentLatest}
                investButtonProperty={investButtonProperty}
              />
            )}
          </Flex>
          <div ref={contactUsRef}>
            <Flex variant='row-center' classes={['mt-88']}>
              <CTABanner
                heading={QUESTION_FOR_PROJECT}
                subHeading={GET_IN_TOUCH_WITH_OWNER}
                ButtonComponent={
                  <Button
                    classes={['auto-btn-width']}
                    variant='primary-btn'
                    text={CONTACT_US}
                    handleClick={handleClickModal}
                  />
                }
              />
            </Flex>
          </div>
          {showContactusModal ? (
            <ContactUsModal active={true} subject={'Inquiry'} handleOnClose={handleClickModal} />
          ) : null}
        </>
      )}
      {similarProjects && similarProjects.length > 0 && (
        <div className='mt-96'>
          <H2 classes={['similar-projects-title']} text={'Similar opportunities'} />
          <div className='similar-projects-container mt-32'>
            {similarProjects.map(
              ({
                id,
                projectSlug,
                closingDate,
                projectName,
                projectSummary,
                committedAmount,
                raiseGoal,
                irr,
                projectCover,
                currencyCode,
                assetClass,
                ticketSize,
              }) => {
                return (
                  <ProjectCard
                    key={id}
                    id={id}
                    projectSlug={projectSlug}
                    closingDate={closingDate}
                    projectName={projectName}
                    projectSummary={projectSummary}
                    commitedPercentage={
                      committedAmount
                        ? calculateCommittedPercentage(committedAmount || '', raiseGoal || '')
                        : '0%'
                    }
                    irr={displayPercentage(irr)}
                    projectCover={projectCover}
                    assetClass={assetClass}
                    ticketSize={displayDollar(ticketSize, currencyCode)}
                    handleClick={handleSimilarProjectClick}
                  />
                )
              },
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export { OpportunityDetailPresenter }
