/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect } from 'react'
import { H4, Flex, Card, P, ProgressBar, H6, Button } from '../../lib/components'
import { OpportunityCompanyDetailProps } from '../../lib/types'
import {
  calculateCommittedPercentage,
  calculateRemainingDays,
  displayPercentage,
  displayDollar,
  calculatePercentage,
  roundPercentage,
  displayReturnsTitle,
} from '../../lib/utils/helpers'
import { KeyFactorSection } from './key-factors'
import { ASSET_CLASSES } from '../../lib/utils/constants'

interface PropertyCardProps {
  fieldName: string
  value: string
}

const PropertyCard = ({ fieldName, value }: PropertyCardProps) => (
  <Card classes={['property-card']}>
    <P text={fieldName} classes={['width-max']} />
    <H4 text={value} />
  </Card>
)

const CompanyCard = ({
  project,
  investHandleClick,
  investButtonProperty,
}: OpportunityCompanyDetailProps) => {
  const [isCardFixed, setCardFixed] = useState(false)
  const committedAmountPercent = roundPercentage(
    calculatePercentage(project.committedAmount, project.raiseGoal),
  ).toString()

  useEffect(() => {
    const handleScroll = () => {
      const companyCard = document.querySelector('#about-company')
      const boundRect = companyCard?.getBoundingClientRect()
      const fixedClassSwitchHeight = 120

      if (boundRect) {
        setCardFixed(boundRect.top <= fixedClassSwitchHeight)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Card classes={['company-card', 'position-sticky']}>
      <Flex classes={['default-progress-card', 'mr-32', 'width-auto-progress-card']}>
        <H6
          text={`${calculateCommittedPercentage(
            project?.committedAmount || '',
            project?.raiseGoal || '',
          )} committed`}
        />
        <ProgressBar variant='golden-bar' value={committedAmountPercent} maxValue='100' />
        <Flex variant='space-between'>
          <Flex variant='row-center'>
            <P
              textSize='small'
              variant='default-gray'
              text={`${displayDollar(project?.committedAmount, project.currencyCode)} of`}
            />
            <H6
              classes={['ml-8']}
              text={displayDollar(project?.raiseGoal, project?.currencyCode) || ''}
            />
          </Flex>
          <H6
            classes={
              project?.closingDate &&
              calculateRemainingDays(new Date(), new Date(project?.closingDate)) <= 0
                ? ['closed-for-investment']
                : ['']
            }
            text={
              project?.closingDate &&
              calculateRemainingDays(new Date(), new Date(project?.closingDate)) > 0
                ? `${
                    project?.closingDate &&
                    calculateRemainingDays(new Date(), new Date(project?.closingDate))
                  } days left`
                : 'Closed for investment'
            }
          />
        </Flex>
      </Flex>
      <Flex classes={['mb-40', 'mr-32', 'ml-32']}>
        {investButtonProperty &&
          investButtonProperty.investmentInfo &&
          investButtonProperty.investmentInfo.props.children && (
            <div
              className='kyc-sub-heading mt-16'
              data-testid='opp-detail-company-card-invest-info'
            >
              <p>{investButtonProperty.investmentInfo}</p>
            </div>
          )}
      </Flex>
      <Flex variant='column'>
        <Button
          classes={['company-card-btn', 'ml-32', 'mr-32', 'mb-12']}
          variant='primary-btn'
          isDisabled={investButtonProperty && investButtonProperty.isDisable}
          text={investButtonProperty && investButtonProperty.buttonText}
          testId='opp-detail-company-card-invest-btn'
          handleClick={() => {
            if (investButtonProperty && !investButtonProperty.isDisable)
              investHandleClick(project.projectSlug, project.id)
          }}
        />
      </Flex>
      {project.keyFactors?.length > 0 && <KeyFactorSection keyFactors={project.keyFactors} />}

      <Flex variant='column' classes={['mb-32', 'ml-40', 'mr-40']}>
        <Flex variant='row' classes={['property-card-container', 'mb-16']}>
          {project.assetClass !== ASSET_CLASSES.LISTED_EQUITIES && project.irr !== '0.00' && (
            <PropertyCard
              fieldName={displayReturnsTitle(project.assetClass)}
              value={
                project.assetClass === ASSET_CLASSES.PRIVATE_EQUITY
                  ? displayDollar(project.irr, project.currencyCode)
                  : displayPercentage(project.irr) || ''
              }
            />
          )}
          <PropertyCard
            fieldName={'Raise Goal'}
            value={displayDollar(project?.raiseGoal, project?.currencyCode) || ''}
          />
          <PropertyCard
            fieldName={'Ticket Size'}
            value={displayDollar(project?.ticketSize, project?.currencyCode) || ''}
          />
        </Flex>
      </Flex>
    </Card>
  )
}

export { CompanyCard }
